import React from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import ExportReports from "./ExportReports";

export default function Reports() {
  const { data, error } = useFetchWithReload(
    `global/Report/field/superAdmin/gard-report-field/field_owner`
  );
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <h1 className=" my-4 text-center text-xl font-bold ">تقارير الساحات</h1>

      {data && (
        <>
          {data.report ? (
            <>
              <ExportReports data={data.report} />
              {data.report.map((user, indx) => (
                <div key={indx}>
                  <h1 className="my-2 text-center text-lg font-semibold ">
                    {user.owner_name}
                  </h1>
                  <table>
                    <thead>
                      <tr>
                        <th>اسم الساحة</th>
                        <th>مساحته</th>
                        <th>المدينه</th>
                        <th>سعر الاشتراك</th>
                        <th>نسبة المنصة</th>
                        <th>عدد المشتركين</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.fields.map((filed, inx) => (
                        <tr key={inx}>
                          <td className="text-center">{filed.field_name}</td>
                          <td className="text-center">{filed.space}</td>
                          <td className="text-center">{filed.city}</td>
                          <td className="text-center">
                            {filed.subscription_price} ر.س
                          </td>
                          <td className="text-center">
                            {filed.platform_fee_percentage}%
                          </td>
                          <td className="text-center">
                            {filed.subscribers_count}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </>
          ) : (
            <h1 className=" text-red-600 text-xl font-bold text-center ">
              لا توجد ساحات
            </h1>
          )}
        </>
      )}
    </div>
  );
}
