import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import usePostFormData from "../../../utils/usePostForm";
import { load } from "../../../utils/isLoadingStore";
import Loader from "../../../components/Loader/Loader";
import ImageCropper from "../../../components/pages/cropper/ImageCropper";
const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(file);
  });
};
export default function CahngeCover({ close, set }) {
  const [file, setFile] = useState();
  const [croppedImage, setCroppedImage] = useState(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setFile(imageDataUrl); // Open cropper with selected image
    }
  };
  const onCropComplete = (croppedBlob) => {
    // setCroppedImage(croppedImageUrl);
    // setFile(null); // Close cropper after cropping
    // Convert the Blob to a File
    const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
      type: "image/jpeg",
    });
    setCroppedImage(croppedFile);
    setFile(null); // Close cropper after cropping
  };
  const { postFormData, error, response } = usePostFormData(
    "camelOwner/Profile/update"
  );
  const handelChange = async () => {
    const formdata = new FormData();
    // formdata.append("cover_image", file);
    formdata.append("cover_image", croppedImage);

    await postFormData(formdata);
  };

  useEffect(() => {
    if (!!response) {
      set((pre) => ({
        ...pre,
        cover: response.camel_owner.cover_image,
      }));

      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className=" almarai-regular rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0 ">
      <section className=" max-h-[95vh] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] w-full rounded-[16px] ">
        {isLoading && <Loader />}
        <section className=" my-2 flex items-center justify-between ">
          <h1 className=" text-[#432315] text-[24px] font-[600] ">
            تغيير صورة الخلفية
          </h1>
          <button
            onClick={() => close(false)}
            className=" text-red-600 font-[600] "
          >
            X
          </button>
        </section>
        <section>
          <h4 className=" text-[#290900] text-[20px] font-[500] my-5 ">
            صورة الخلفية
          </h4>
          {croppedImage && (
            <div className=" relative">
              <button //Delete Btn
                onClick={() => {
                  setFile();
                }}
                className=" text-red-500/70 hover:text-red-500 cursor-pointer text-lg "
              >
                {/* <i className="fa-solid fa-trash"></i> */}
              </button>{" "}
              {/*  Video */}
              <img
                alt="new cover"
                className=" max-h-[600px] max-w-full mx-auto  "
                controls
                src={URL.createObjectURL(croppedImage)}
              />
            </div>
          )}
          {file && (
            <ImageCropper imageSrc={file} onCropComplete={onCropComplete} w={1440} h={280} />
          )}
          {!croppedImage && !file && (
            <div className=" font-bold text-2xl p-8   flex items-center justify-center mx-auto bg-[#F5EFDC] rounded-[16px] ">
              {/* Upload Video */}
              <section>
                <label className=" cursor-pointer h-full w-full" htmlFor="file">
                  <section className="  text-center ">
                    {" "}
                    <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#A59382] text-center "></i>
                    <h1 className="text-[#A59382]">رفع الصورة</h1>
                  </section>
                </label>

                <input
                  required
                  type="file"
                  id="file"
                  // onChange={(e) => {
                  //   setFile(e.target.files[0]);
                  // }}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </section>
            </div>
          )}
        </section>
        <hr className=" my-5 bg-[#290900] border-[#290900]   "></hr>
        <section className=" flex justify-end ">
          <button
            onClick={handelChange}
            disabled={!croppedImage}
            className=" btn2 disabled:opacity-70 "
          >
            حفظ
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response.data.message
              ? error.response.data.message
              : "  هناك مشكلة"}
          </section>
        )}
      </section>
    </div>,
    document.body // Renders the component at the root level of the DOM
  );
}
