import { Menu } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import font from "../../../../../utils/Amiri-Regular-normal";
const types = {
  camel_owner: "مالك ابل",
  field_owner: "مالك ساحة",
  service_provider: "مقدم خدمة",
  visitor: "زائر",
};

const generatePDF = (data, type) => {
  const doc = new jsPDF();

  doc.addFileToVFS("Amiri-Regular.ttf", font);
  doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
  doc.setFont("Amiri");
  doc.setFontSize(12);

  const tableColumn = [
    "عدد المشتركين",
    "نسبة التحالف",
    "المسئول",
    "اسم التحالف",
  ];
  const tableRows = [];

  data.forEach((item, index) => {
    item.alliances.forEach((i) => {
      const tableData = [
        i.subscribers_count,
        i.percentage,
        i.leader,
        i.alliance_name,
      ];
      tableRows.push(tableData);
    });
  });

  doc.text(`تقارير التحالف - ${types[type]} `, 190, 15, { align: "right" });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 25,
    styles: {
      font: "Amiri",
      fontSize: 12,
    },
    bodyStyles: { valign: "middle", halign: "right" },
    headStyles: { fillColor: [63, 81, 181], halign: "right" },
    tableLineWidth: 0.1,
    margin: { left: 10, right: 10 },
  });
  doc.save(`تقارير التحالف - ${types[type]}.pdf`);
};

export default function ExportReports({ data, type }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const headers = [
    { label: "اسم التحالف", key: "name" },
    { label: "المسئول", key: "owner" },
    { label: "نسبة التحالف", key: "percent" },
    { label: "نسبة المنصة", key: "platformPercent" },
    { label: "تاريخ النشر", key: "date" },
    { label: "عدد المشتركين", key: "subs" },
  ];

  const [body, setBody] = useState([]);
  useEffect(() => {
    if (data) {
      let co = [];
      // eslint-disable-next-line array-callback-return
      data.map((item) => {
        item.alliances.forEach((i) => {
          co.push({
            name: i.alliance_name,
            owner: i.leader,
            percent: i.percentage,
            platformPercent: i.platform_fee,
            date: i.published_date,
            subs: i.subscribers_count,
          });
        });
      });
      setBody(co);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div>
      <section className=" my-2 flex items-center justify-end ">
        <button
          onClick={handleClick}
          className=" btn2 flex items-center justify-center gap-2 "
        >
          تصدير <i class="fa-solid fa-caret-down" />
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <button
            onClick={() => {
              handleClose();
              generatePDF(data, type);
            }}
            className=" py-2 px-12 border-b flex items-center justify-center gap-2 "
          >
            PDF <i className="fa-solid fa-file-pdf"></i>
          </button>
          <button
            onClick={() => {
              handleClose();
            }}
            className=" "
          >
            <CSVLink
              data={body}
              headers={headers}
              filename={`تقارير التحالف - ${types[type]}.csv`}
              target="_blank"
              className=" w-full  py-2 px-12 border-b flex items-center justify-center gap-2 "
            >
              CVS <i className="fa-solid fa-table"></i>
            </CSVLink>
          </button>
        </Menu>
      </section>
    </div>
  );
}
