import React from "react";

export default function CamelComponent({ data }) {
  return (
    // <div className=" mx-auto md:mx-0 mt-[-20px] w-full max-w-[280px] lg:max-w-[330px] sh p-4 bg-[#F5EFDC] rounded-[15px] ">
    <div className=" mx-auto md:mx-0  w-full  md:max-w-[330px] sh p-4 bg-[#F5EFDC] rounded-[15px] ">
      <img
        src={`${process.env.REACT_APP_URL}/${data.image}`}
        alt={`جمل ${data.name}`}
        className=" w-full h-[182px] rounded-[15px] "
      />
      <section className=" flex items-center gap-1 text-[18px] text-[#290900] font-[800] my-2 ">
        <h1>اسم الإبل : </h1>
        <h1>{data.name}</h1>
      </section>
      <div className=" grid  grid-cols-2 gap-x-4 ">
        <section className=" col-span-1 flex items-center gap-1 text-[18px] leading-[33px] my-2 ">
          <h1> النوع : </h1>
          <h1 className=" text-[#290900] font-[800]">{data.type}</h1>
        </section>
        <section className=" col-span-1 flex items-center gap-1 text-[18px] leading-[33px] my-2 ">
          <h1> اللون : </h1>
          <h1 className=" text-[#290900] font-[800]">{data.color}</h1>
        </section>
        <section className=" col-span-1 flex items-center gap-1 text-[18px] leading-[33px] my-2 ">
          <h1> العمر : </h1>
          <h1 className=" text-[#290900] font-[800]">{data.age}</h1>
        </section>
        <section className=" col-span-1 flex items-center gap-1 text-[18px] leading-[33px] my-2 ">
          <h1> الجنس : </h1>
          <h1 className=" text-[#290900] font-[800]">{data.gender}</h1>
        </section>
        <section className=" col-span-1 flex items-center gap-1 text-[18px] leading-[33px] my-2 ">
          <h1> السلالة : </h1>
          <h1 className=" text-[#290900] font-[800]">{data.season}</h1>
        </section>
      </div>
    </div>
  );
}
