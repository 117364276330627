import React from "react";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import ExportReports from "./ExportReports";

export default function GenralReports({ back }) {
  let link = "global/Report/race/owner/detailed-report";
  const { data, error } = useFetchWithToken(link);
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <section>
        <button onClick={() => back()} className=" text-[18px] ">
          <i className="fa-solid fa-angle-right ml-2 "></i>الرجوع
        </button>
      </section>
      <h1 className=" text-xl font-bold text-center">تقارير السباقات</h1>
      {data && <ExportReports data={data.report.races} />}
      {data && data.report && (
        <table>
          <thead>
            <tr>
              <th>اسم السباق</th>
              <th>عدد المشاركين</th>
              <th>تاريخ البداية</th>
              <th>تاريخ النهاية</th>
              <th> اجمالي الارباح</th>
              <th> نسبة المنصة</th>
            </tr>
          </thead>
          <tbody>
            {data.report.races.map((race, inx) => (
              <tr key={inx}>
                <td className=" text-center ">{race.race_name}</td>
                <td className=" text-center ">{race.total_participants}</td>
                <td className=" text-center ">{race.start_date}</td>
                <td className=" text-center ">{race.end_date}</td>
                <td className=" text-center ">{race.total_revenue} ر.س </td>
                <td className=" text-center ">{race.platform_percentage}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
