import React, { useContext } from "react";
import { openMessage } from "../utils/openMessageStore";

export default function NoProfileImage({ open }) {
  const { setOpenMessageState } = useContext(openMessage);
  const close = () => {
    setOpenMessageState(false);
  };
  const openChange = () => {
    close();
    open();
  };
  return (
    <div className=" fadein z-[200] fixed top-0 left-0 h-screen w-full bg-[#00000080] flex items-center justify-center px-6 ">
      <section className=" text-[#432315] w-full max-w-[700px] max-h-[95vh] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0]  rounded-[16px] ">
        <h1 className=" text-center my-5 text-[35px] font-bold ">
          مرحبا بك مرة ثانية
        </h1>
        <h4 className="text-center my-5 text-[20px] font-bold">
          نتمني إكمال بياناتك ورفع صورة لتجربة أفضل
        </h4>

        <section className=" mt-14 flex items-center justify-center gap-6 flex-wrap ">
          <button onClick={openChange} className=" btn2 disabled:opacity-70 ">
            رفع صورة
          </button>
          <button onClick={close} className=" btn2 disabled:opacity-70 ">
            اغلاق
          </button>
        </section>
      </section>
    </div>
  );
}
