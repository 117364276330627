import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { load } from "../../../../utils/isLoadingStore";
import Loader from "../../../../components/Loader/Loader";
import FileUploded from "../../../../components/pages/FileUploded";
import { isLogged } from "../../../../utils/inStore";
import { useNavigate } from "react-router";
import usePostFormData from "../../../../utils/usePostForm";
import ImageCropper from "../../../../components/pages/cropper/ImageCropper";
import { Link } from "react-router-dom";
const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(file);
  });
};
export default function AddTrip({ close }) {
  const [form, setForm] = useState({
    title: "",
    address: "",
    price_adult: "",
    price_child: "",
    description: "",
    tour_duration: "",
    tour_location: "",
    map_url: "",
    tour_type: "",
    pickup_time: "",
    meeting_point: "",
    tour_date: "",

    card_image: "",
    details_image: [],
    included_services: [],
  });
  const [croppedImage, setCroppedImage] = useState(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setForm({ ...form, card_image: imageDataUrl });
    }
  };
  const onCropComplete = (croppedBlob) => {
    // setCroppedImage(croppedImageUrl);
    // setFile(null); // Close cropper after cropping
    // Convert the Blob to a File
    const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
      type: "image/jpeg",
    });
    setCroppedImage(croppedFile);
    setForm({ ...form, card_image: null }); // Close cropper after cropping
  };
  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handelChanegeService = (e, inx) => {
    const { value } = e.target;
    const updatedAgenda = form.included_services.map((item, index) =>
      index === inx ? value : item
    );

    setForm((prevState) => ({
      ...prevState,
      included_services: updatedAgenda,
    }));
  };

  const [errors, setErrors] = useState({});

  const validate = () => {
    let formErrors = {};

    // Validate title
    if (!form.title) {
      formErrors.title = "الرجاء إدخال عنوان الرحلة";
    }

    // Validate address
    if (!form.address) {
      formErrors.address = "الرجاء إدخال عنوان الرحلة";
    }

    // Validate price for adults
    if (!form.price_adult) {
      formErrors.price_adult = "الرجاء إدخال سعر البالغين";
    }

    // Validate price for children
    if (!form.price_child) {
      formErrors.price_child = "الرجاء إدخال سعر الأطفال";
    }

    // Validate description
    if (!form.description) {
      formErrors.description = "الرجاء إدخال وصف الرحلة";
    }

    // Validate tour duration
    if (!form.tour_duration) {
      formErrors.tour_duration = "الرجاء إدخال مدة الرحلة";
    }

    // Validate tour location
    if (!form.tour_location) {
      formErrors.tour_location = "الرجاء إدخال موقع الرحلة";
    }

    // Validate map URL
    if (!form.map_url) {
      formErrors.map_url = "الرجاء إدخال رابط الخريطة";
    }

    // Validate tour type
    if (!form.tour_type) {
      formErrors.tour_type = "الرجاء تحديد نوع الرحلة";
    }

    // Validate pickup time
    if (!form.pickup_time) {
      formErrors.pickup_time = "الرجاء إدخال وقت التجميع";
    }

    // Validate meeting point
    if (!form.meeting_point) {
      formErrors.meeting_point = "الرجاء إدخال نقطة الالتقاء";
    }
    // Validate meeting point
    if (!form.tour_date) {
      formErrors.tour_date = "الرجاء إدخال تاريخ الرحلة";
    }

    // Validate card image
    if (!croppedImage) {
      formErrors.card_image = "الرجاء رفع الصورة المصغرة للرحلة";
    }

    // Validate details image (optional)
    if (!form.details_image || form.details_image.length === 0) {
      formErrors.details_image = "الرجاء رفع الصور التفصيلية للرحلة";
    }

    // Validate included services
    if (!form.included_services || form.included_services.length === 0) {
      formErrors.included_services = "الرجاء إدخال الخدمات المشمولة في الرحلة";
    }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };

  const { role } = useContext(isLogged);
  let link = "";
  if (role === "camel-owner") {
    link = "camelOwner/tourism/create";
  }
  if (role === "squer") {
    link = "field-owner/tourism/create";
  }
  if (role === "provider") {
    link = "serviceProvider/tourism/create";
  }
  if (role === "super_admin") {
    link = "superAdmin/tourism/create";
  }
  const roles = {
    "camel-owner": "camel_owner",
    squer: "field_owner",
    provider: "service_provider",
    vistor: "visitor",
    super_admin: "super_admin",
  };

  const navigate = useNavigate();
  const { postFormData, error, response } = usePostFormData(link);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formdata = new FormData();
      formdata.append("title", form.title);
      formdata.append("description", form.description);
      formdata.append("price_adult", form.price_adult);
      formdata.append("price_child", form.price_child);
      formdata.append("address", form.address);
      formdata.append("tour_duration", form.tour_duration);
      formdata.append("tour_location", form.tour_location);
      formdata.append("map_url", form.map_url);
      formdata.append("tour_type", form.tour_type);
      formdata.append("pickup_time", form.pickup_time);
      formdata.append("meeting_point", form.meeting_point);
      formdata.append("tour_date", form.tour_date);
      formdata.append("card_image", croppedImage);
      form.details_image.forEach((image) => {
        formdata.append("details_image[]", image); // Check if your server expects "details_image" or "details_image[]"
      });
      form.included_services.forEach((image) => {
        formdata.append(`included_services[]`, image); // Check if your server expects "details_image" or "details_image[]"
      });

      await postFormData(formdata);
    }
  };

  useEffect(() => {
    if (!!response) {
      navigate(`/trip-details?trip=${response.id}&role=${roles[role]}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">اضافة رحلة</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الصورة المصغرة
            </h1>
            {croppedImage && (
              <div className="relative">
                <button
                  onClick={() => setCroppedImage(null)}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[230px] rounded-[20px] max-w-full mx-auto"
                  // src={URL.createObjectURL(file)}
                  src={URL.createObjectURL(croppedImage)}
                />
              </div>
            )}
            {!croppedImage && !form.card_image && (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  // onChange={(e) => {
                  //   setForm({ ...form, card_image: e.target.files[0] });
                  // }}
                  onChange={handleFileChange}
                />
              </section>
            )}
            {form.card_image && (
              <ImageCropper
                imageSrc={form.card_image}
                onCropComplete={onCropComplete}
                w={300}
                h={180}
              />
            )}
            {<p className="error">{errors.card_image}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم الرحلة
            </h1>
            <input
              name="title"
              value={form.title}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="اسم الرحلة"
              required
            />
            {<p className="error">{errors.title}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              العنوان
            </h1>
            <input
              name="address"
              value={form.address}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" العنوان"
              required
            />
            {<p className="error">{errors.address}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              سعر الفرد البالغين
            </h1>
            <input
              name="price_adult"
              value={form.price_adult}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              min={1}
              placeholder=" السعر  بالريال السعودي"
              required
            />
            {<p className="error">{errors.price_adult}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              سعر الفرد للصغير
            </h1>
            <input
              name="price_child"
              value={form.price_child}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              min={1}
              placeholder=" السعر  بالريال السعودي"
              required
            />
            {<p className="error">{errors.price_child}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نبذة عن الرحلة
            </h1>
            <textarea
              name="description"
              cols={2}
              value={form.description}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" نبذة عن الرحلة "
              required
            />
            {<p className="error">{errors.description}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              مدة الرحلة
            </h1>
            <input
              name="tour_duration"
              value={form.tour_duration}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              min={0}
              placeholder=" مدة الرحلة بالساعات"
              required
            />
            {<p className="error">{errors.tour_duration}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              مكان الرحلة
            </h1>
            <input
              name="tour_location"
              value={form.tour_location}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="مكان الرحلة"
              required
            />
            {<p className="error">{errors.tour_location}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رابط العنوان من الخريطة
            </h1>
            <input
              name="map_url"
              value={form.map_url}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" رابط العنوان من الخريطة"
              required
            />
            {<p className="error">{errors.map_url}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نوع الرحلة
            </h1>
            <input
              name="tour_type"
              value={form.tour_type}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="نوع الرحلة"
              required
            />
            {<p className="error">{errors.tour_type}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              وقت الانطلاق
            </h1>
            <input
              name="pickup_time"
              value={form.pickup_time}
              onChange={handelChanege}
              className=" inpt "
              type="time"
              placeholder="وقت الانطلاق"
              required
            />
            {<p className="error">{errors.pickup_time}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              مكان الانطلاق
            </h1>
            <input
              name="meeting_point"
              value={form.meeting_point}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" مكان الانطلاق"
              required
            />
            {<p className="error">{errors.meeting_point}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تاريخ الرحلة
            </h1>
            <input
              name="tour_date"
              value={form.tour_date}
              onChange={handelChanege}
              className=" inpt "
              type="date"
              placeholder="تاريخ الرحلة"
              required
            />
            {<p className="error">{errors.tour_date}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              صور الرحلة
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.details_image.map((e, inx) => {
                // console.log(e.name);
                return (
                  <FileUploded
                    key={inx}
                    da={e}
                    del={() => {
                      let da = form.details_image;
                      da = da.filter((i, index) => inx !== index);
                      setForm({
                        ...form,
                        details_image: da,
                      });
                    }}
                  />
                );
              })}

              <label className="btn2" htmlFor="imgs">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع الصورة</h1>
                </section>
              </label>
              <input
                type="file"
                id="imgs"
                accept="image/*"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      details_image: [...form.details_image, e.target.files[0]],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
            {<p className="error">{errors.details_image}</p>}
          </section>
          <section className=" col-span-1 text-xl font-bold lg:col-span-2 text-center ">
            الخدمات المُدرجة
          </section>
        </form>
        {form.included_services.map((e, inx) => (
          <div key={inx} className=" col-span-1 lg:col-span-2  ">
            <section className=" col-span-1 lg:col-span-2 flex items-center justify-between p-2 ">
              <h1 className=" h-[30px] w-[30px] flex items-center justify-center text-lg rounded-full text-[#fafafa] bg-[#432315] ">
                {inx + 1}
              </h1>
              <button
                onClick={() => {
                  let newAgenda = form.included_services.filter(
                    (e, index) => inx !== index
                  );
                  setForm({ ...form, included_services: newAgenda });
                }}
              >
                <i className="fa-solid fa-trash text-red-600"></i>
              </button>
            </section>
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                الخدمة
              </h1>
              <input
                name="tour_duration"
                value={e}
                onChange={(e) => handelChanegeService(e, inx)}
                className=" inpt "
                type="text"
                placeholder="الخدمة"
                required
              />
              {/* {<p className="error">{errors.tour_duration}</p>} */}
            </section>
          </div>
        ))}
        <section className="  col-span-1 lg:col-span-2 text-center ">
          <button
            onClick={() => {
              setForm({
                ...form,
                included_services: [...form.included_services, ""],
              });
            }}
            className="btn2 my-3"
          >
            اضافة خدمة
          </button>
          <br />
          {<p className="error">{errors.included_services}</p>}
        </section>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handleSubmit} className="btn9">
            اضافة
          </button>

          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response ? (
              <>
                {!!error.response.data ? (
                  <>
                    {!!error.response.data.error ? (
                      <>
                        {error.response.data.error ===
                        "ليس لديك اشتراك نشط." ? (
                          <section className=" flex items-center gap-2 flex-wrap ">
                            {error.response.data.error}
                            <Link
                              className=" underline text-[15px] p-2  font-bold "
                              to="/packages"
                            >
                              {" "}
                              عرض الباقات
                            </Link>
                          </section>
                        ) : (
                          error.response.data.error
                        )}
                      </>
                    ) : (
                      "  هناك مشكلة"
                    )}
                  </>
                ) : (
                  <>هناك مشكلة</>
                )}
              </>
            ) : (
              <>هناك مشكلة</>
            )}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
