import React from "react";
import MyRaces from "../dashboard/pages/MyJoins/races/MyRaces";

export default function JoinsRaces() {
  return (
    <div className=" py-12 px-6 md:p-12 ">
      <h1 className=" text-[36px] font-[800] text-center text-[#432315] ">
        مشاراكاتي في السباقات
      </h1>
      <div className=" my-6 ">
        <MyRaces />
      </div>
    </div>
  );
}
