import React from "react";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import ExportsAttends from "./ExportsAttends";

export default function Attends({ back, id }) {
  let link = `global/Report/race/all-subscribers/${id}`;
  const { data, error } = useFetchWithToken(link);
  console.log(data);
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <section>
        <button onClick={() => back()} className=" text-[18px] ">
          <i className="fa-solid fa-angle-right ml-2 "></i>الرجوع
        </button>
      </section>
      {data && (
        <div>
          {data.report ? (
            <div>
              <h1 className=" my-2 text-lg ">
                اسم السباق :{" "}
                <span className=" text-xl font-bold ">
                  {data.report.race_name}
                </span>
              </h1>
              {/* <h1 className=" my-2 text-lg ">
                سعر الاشتراك :{" "}
                <span className=" text-xl font-bold ">
                  {data.report.fees} ر.س
                </span>
              </h1> */}
              <h1 className=" my-2 text-lg ">
                عدد المشتركين :{" "}
                <span className=" text-xl font-bold ">
                  {data.report.subscribers_count}
                </span>
              </h1>
              <h1 className=" text-xl my-4 font-bold text-center ">
                المشتركين
              </h1>{" "}
              {data && (
                <ExportsAttends
                  data={data.report.subscribers}
                  num={data.report.subscribers_count}
                  name={data.report.race_name}
                />
              )}
              {data.report.subscribers.map((user, inx) => (
                <div
                  className=" my-2 border border-black/70 p-4 rounded-[16px] "
                  key={inx}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>اسم المتسابق</th>
                        <th>رقم الهاتف</th>
                        <th>البريد الاكتروني</th>
                        {/* <th>فئة السباق</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className=" text-center ">{user.name}</td>
                        <td className=" text-center ">{user.phone}</td>
                        <td className=" text-center ">{user.email}</td>
                        {/* <td className=" text-center ">{user.name}</td> */}
                      </tr>
                    </tbody>
                  </table>
                  <h1 className=" text-lg my-4 font-semibold text-center ">
                    اسم الابل :{" "}
                    <span className=" text-xl font-bold ">
                      {user.camel_details.camel_name}
                    </span>
                  </h1>
                  <table>
                    <thead>
                      <tr>
                        <th>العمر</th>
                        <th>اللون</th>
                        <th>السلالة</th>
                        <th>رقم التعريف</th>
                        <th>شهادات الملكية</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className=" text-center ">
                          {user.camel_details.age} سنة{" "}
                        </td>

                        <td className=" text-center ">
                          {user.camel_details.color}
                        </td>
                        <td className=" text-center ">
                          {user.camel_details.type}
                        </td>
                        <td className=" text-center ">
                          {user.camel_details.definition_number}
                        </td>
                        <td className=" text-center ">
                          {user.camel_details.certificate_ownership}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          ) : (
            <h1 className=" text-red-600 text-xl font-bold text-center ">
              لا يوجد مشاركين حتي الان
            </h1>
          )}
        </div>
      )}
    </div>
  );
}
