import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FileUploded from "../../../../components/pages/FileUploded";
import usePostFormData from "../../../../utils/usePostForm";
import Loader from "../../../../components/Loader/Loader";
import { load } from "../../../../utils/isLoadingStore";
import { isLogged } from "../../../../utils/inStore";
import { useNavigate } from "react-router";
import ImageCropper from "../../../../components/pages/cropper/ImageCropper";
import { Link } from "react-router-dom";
const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(file);
  });
};
export default function AddInvestMent({ close }) {
  const [form, setForm] = useState({
    title: "",
    company_name: "",
    summary: "",
    details: "",
    start_date: "",
    end_date: "",
    price_per_share: "",
    total_investment: "",
    city: "",
    address: "",
    available_shares: "",
    card_image: "",
    investment_files: [],
    details_image: [],
  });
  const [croppedImage, setCroppedImage] = useState(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setForm({ ...form, card_image: imageDataUrl });
    }
  };
  const onCropComplete = (croppedBlob) => {
    // setCroppedImage(croppedImageUrl);
    // setFile(null); // Close cropper after cropping
    // Convert the Blob to a File
    const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
      type: "image/jpeg",
    });
    setCroppedImage(croppedFile);
    setForm({ ...form, card_image: null }); // Close cropper after cropping
  };
  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handelChanegeShares = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
      total_investment: value * Number(form.price_per_share),
    });
  };
  const handelChanegeprice = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
      total_investment: value * Number(form.available_shares),
    });
  };
  const handelChanegeDetails = (value) => {
    setForm({ ...form, details: value });
  };

  // Manual validation logic
  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};

    // Validate title
    if (!form.title) {
      formErrors.title = "الرجاء إدخال عنوان الاستثمار";
    }

    // Validate company name
    if (!form.company_name) {
      formErrors.company_name = "الرجاء إدخال اسم الشركة";
    }

    // Validate summary
    if (!form.summary) {
      formErrors.summary = "الرجاء إدخال نبذة عن الاستثمار";
    }

    // // Validate details
    // if (!form.details) {
    //   formErrors.details = "الرجاء إدخال تفاصيل الاستثمار";
    // }

    // // Validate start date
    // if (!form.start_date) {
    //   formErrors.start_date = "الرجاء إدخال تاريخ البداية";
    // }

    // // Validate end date
    // if (!form.end_date) {
    //   formErrors.end_date = "الرجاء إدخال تاريخ النهاية";
    // }

    // Validate price per share
    if (!form.price_per_share) {
      formErrors.price_per_share = "الرجاء إدخال سعر السهم";
    } else if (isNaN(form.price_per_share) || form.price_per_share <= 0) {
      formErrors.price_per_share = "يجب أن يكون سعر السهم رقمًا موجبًا";
    }
    if (!form.available_shares) {
      formErrors.available_shares = "الرجاء إدخال عدد الاسهم";
    } else if (isNaN(form.available_shares) || form.available_shares <= 0) {
      formErrors.available_shares = "يجب أن يكون عدد الاسهم رقمًا موجبًا";
    }

    // Validate total investment
    if (!form.total_investment) {
      formErrors.total_investment = "الرجاء إدخال إجمالي الاستثمارات";
    } else if (isNaN(form.total_investment) || form.total_investment <= 0) {
      formErrors.total_investment =
        "يجب أن يكون إجمالي الاستثمارات رقمًا موجبًا";
    }

    // Validate city
    if (!form.city) {
      formErrors.city = "الرجاء إدخال المدينة";
    }

    // Validate address
    if (!form.address) {
      formErrors.address = "الرجاء إدخال العنوان";
    }

    // Validate card image
    if (!croppedImage) {
      formErrors.card_image = "الرجاء رفع الصورة المصغرة";
    }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };

  const { role } = useContext(isLogged);
  let link = "";
  if (role === "camel-owner") {
    link = "camelOwner/investments/create";
  }
  if (role === "squer") {
    link = "field-owner/investments/create";
  }
  if (role === "provider") {
    link = "serviceProvider/investments/create";
  }
  if (role === "super_admin") {
    link = "superAdmin/investments/create";
  }
  const roles = {
    "camel-owner": "camel_owner",
    squer: "field_owner",
    provider: "service_provider",
    vistor: "visitor",
    super_admin: "super_admin",
  };

  const navigate = useNavigate();

  const { postFormData, error, response } = usePostFormData(link);
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formdata = new FormData();
      formdata.append("title", form.title);
      formdata.append("company_name", form.company_name);
      formdata.append("summary", form.summary);
      formdata.append("details", form.details);
      // formdata.append("start_date", form.start_date);
      // formdata.append("end_date", form.end_date);
      formdata.append("price_per_share", form.price_per_share);
      formdata.append("total_investment", form.total_investment);
      formdata.append("city", form.city);
      formdata.append("address", form.address);
      formdata.append("available_shares", form.available_shares);

      // Append investment files and details images correctly
      form.investment_files.forEach((file) => {
        formdata.append("investment_files[]", file);
      });

      form.details_image.forEach((image) => {
        formdata.append("details_image[]", image);
      });

      formdata.append("card_image", croppedImage);

      // Log the FormData
      // for (let pair of formdata.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }

      await postFormData(formdata);
    }
  };
  useEffect(() => {
    if (!!response) {
      navigate(`/investment-details?invest=${response.id}&role=${roles[role]}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">اضافة اسثمار</h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الصورة المصغرة
            </h1>
            {croppedImage && (
              <div className="relative">
                <button
                  onClick={() => setCroppedImage(null)}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[230px] rounded-[20px] max-w-full mx-auto"
                  // src={URL.createObjectURL(file)}
                  src={URL.createObjectURL(croppedImage)}
                />
              </div>
            )}
            {!croppedImage && !form.card_image && (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  // onChange={(e) => {
                  //   setForm({ ...form, card_image: e.target.files[0] });
                  // }}
                  onChange={handleFileChange}
                />
              </section>
            )}
            {form.card_image && (
              <ImageCropper
                imageSrc={form.card_image}
                onCropComplete={onCropComplete}
                w={300}
                h={180}
              />
            )}
            {<p className="error">{errors.card_image}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم الاستثمار
            </h1>
            <input
              name="title"
              value={form.title}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="اسم الاستثمار"
              required
            />
            {<p className="error">{errors.title}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اسم الشركة
            </h1>
            <input
              name="company_name"
              value={form.company_name}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="اسم الشركة"
              required
            />
            {<p className="error">{errors.company_name}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نبذة عن الاستثمار
            </h1>
            <textarea
              name="summary"
              cols={2}
              value={form.summary}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" نبذة عن الاستثمار "
              required
            />
            {<p className="error">{errors.summary}</p>}
          </section>
          <section className=" min-h-[300px] col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تفاصيل الاستثمار
            </h1>
            <ReactQuill
              value={form.details}
              onChange={handelChanegeDetails}
              className="text-editor  h-[200px]  rtl"
              style={{ direction: "rtl", textAlign: "right" }}
            />
          </section>
          {/* <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تاريخ البداية
            </h1>
            <input
              name="start_date"
              value={form.start_date}
              onChange={handelChanege}
              className=" inpt "
              type="date"
              placeholder="تاريخ البداية"
              required
            />
            {<p className="error">{errors.start_date}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تاريخ النهاية
            </h1>
            <input
              name="end_date"
              value={form.end_date}
              onChange={handelChanege}
              className=" inpt "
              type="date"
              placeholder="تاريخ النهاية"
              required
            />
            {<p className="error">{errors.end_date}</p>}
          </section> */}
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              سعر السهم
            </h1>
            <input
              name="price_per_share"
              value={form.price_per_share}
              onChange={handelChanegeprice}
              className=" inpt "
              type="number"
              min={1}
              placeholder="سعر السهم"
              required
            />
            {<p className="error">{errors.price_per_share}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              الاسهم المتاحة
            </h1>
            <input
              name="available_shares"
              value={form.available_shares}
              onChange={handelChanegeShares}
              className=" inpt "
              type="number"
              min={1}
              placeholder="الاسهم المتاحة"
              required
            />
            {<p className="error">{errors.available_shares}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              اجمالي الاستثمارات
            </h1>
            <input
              name="total_investment"
              value={form.total_investment}
              onChange={handelChanege}
              className=" inpt "
              type="number"
              min={1}
              disabled
              placeholder=" اجمالي الاستثمارات"
              required
            />
            {<p className="error">{errors.total_investment}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              المدينة
            </h1>
            <input
              name="city"
              value={form.city}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="المدينة"
              required
            />
            {<p className="error">{errors.city}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              العنوان
            </h1>
            <input
              name="address"
              value={form.address}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="العنوان"
              required
            />
            {<p className="error">{errors.address}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              صور الاستثمار
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.details_image.map((e, inx) => {
                // console.log(e.name);
                return (
                  <>
                    <FileUploded
                      key={inx}
                      da={e}
                      del={() => {
                        let da = form.details_image;
                        da = da.filter((i, index) => inx !== index);
                        setForm({
                          ...form,
                          details_image: da,
                        });
                      }}
                    />
                  </>
                );
              })}

              <label className="btn2" htmlFor="imgs">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع الصورة</h1>
                </section>
              </label>
              <input
                type="file"
                id="imgs"
                accept="image/*"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      details_image: [...form.details_image, e.target.files[0]],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              ملفات الاستثمار
            </h1>
            <section className=" flex items-center flex-wrap gap-2 ">
              {form.investment_files.map((e, inx) => (
                <>
                  <FileUploded
                    key={inx}
                    da={e}
                    del={() => {
                      let da = form.investment_files;
                      da = da.filter((i, index) => inx !== index);
                      setForm({
                        ...form,
                        investment_files: da,
                      });
                    }}
                  />
                </>
              ))}

              <label className="btn2" htmlFor="files">
                <section className="">
                  <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                  <h1 className="text-[#f2f2f2]">رفع ملف</h1>
                </section>
              </label>
              <input
                type="file"
                id="files"
                onChange={(e) => {
                  if (!!e.target.files[0]) {
                    setForm({
                      ...form,
                      investment_files: [
                        ...form.investment_files,
                        e.target.files[0],
                      ],
                    });
                  }
                }}
                style={{ display: "none" }}
              />
            </section>
          </section>
        </form>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handleSubmit} className="btn9">
            اضافة
          </button>
          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response ? (
              <>
                {!!error.response.data ? (
                  <>
                    {!!error.response.data.error ? (
                      <>
                        {error.response.data.error ===
                        "ليس لديك اشتراك نشط." ? (
                          <section className=" flex items-center gap-2 flex-wrap ">
                            {error.response.data.error}
                            <Link
                              className=" underline text-[15px] p-2  font-bold "
                              to="/packages"
                            >
                              {" "}
                              عرض الباقات
                            </Link>
                          </section>
                        ) : (
                          error.response.data.error
                        )}
                      </>
                    ) : (
                      "  هناك مشكلة"
                    )}
                  </>
                ) : (
                  "  هناك مشكلة"
                )}
              </>
            ) : (
              "  هناك مشكلة"
            )}
          </section>
        )}
        {response && <p>done</p>}
      </section>
    </div>,
    document.body
  );
}
