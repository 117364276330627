import React, { useState } from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import ExportReports from "./ExportReports";

export default function Reports() {
  const [reload, setReload] = useState(false);
  const [type, setType] = useState("camel_owner");
  const handelChange = (e) => {
    setType(e.target.value);
    setReload(!reload);
  };
  const { data, error } = useFetchWithReload(
    `global/Report/alliance/superAdmin/gard-report-alliance/${type}`,
    reload
  );
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center justify-end flex-wrap gap-y-3 md:gap-2">
        <select
          className=" bg-white/0 w-full max-w-[250px] outline-none p-2  border rounded-[11px] border-black/60 "
          value={type}
          onChange={handelChange}
        >
          <option value={"camel_owner"}>مالك ابل</option>
          <option value={"visitor"}>زائر</option>
          <option value={"service_provider"}>مزود خدمة</option>
          <option value={"field_owner"}>مالك ساحة</option>
        </select>
      </div>
      <h1 className=" my-4 text-center text-xl font-bold ">تقارير التحالف</h1>
      {data && (
        <>
          {data.report ? (
            <>
              <ExportReports data={data.report} type={type} />
              {data.report.map((user, indx) => (
                <div key={indx}>
                  <h1 className="my-2 text-center text-lg font-semibold ">
                    {user.owner_name}
                  </h1>
                  <table>
                    <thead>
                      <tr>
                        <th>اسم التحالف</th>
                        <th>المسئول</th>
                        <th>نسبة التحالف</th>
                        <th>نسبة المنصة</th>
                        <th>تاريخ النشر</th>
                        <th>عدد المشتركين</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.alliances.map((alli, inx) => (
                        <tr key={inx}>
                          <td className=" text-center">{alli.alliance_name}</td>
                          <td className=" text-center">{alli.leader}</td>
                          <td className=" text-center">{alli.percentage}%</td>
                          <td className=" text-center">{user.platform_fee}%</td>
                          <td className=" text-center">
                            {alli.published_date}
                          </td>
                          <td className=" text-center">
                            {alli.subscribers_count}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </>
          ) : (
            <h1 className=" text-red-600 text-xl font-bold text-center ">
              لا توجد تحالفات
            </h1>
          )}
        </>
      )}
    </div>
  );
}
