import { Menu } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import font from "../../../../../utils/Amiri-Regular-normal";

const names = {
  camel_owners: "مالك ابل",
  field_owners: "مالك ساحة",
  service_providers: "مقدم خدمة",
  visitors: "زائر",
};
const types = {
  camel_owner: "مالك ابل",
  field_owner: "مالك ساحة",
  service_provider: "مقدم خدمة",
  visitor: "زائر",
};
const generatePDF = (data, type) => {
  const doc = new jsPDF();

  doc.addFileToVFS("Amiri-Regular.ttf", font);
  doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
  doc.setFont("Amiri");
  doc.setFontSize(12);

  const tableColumn = ["الهاتف", "البريد الالكتروني", "نوع المستخدم", "الاسم"];
  const tableRows = [];

  data.forEach((item, index) => {
    const tableData = [
      item.phone,
      item.email,
      types[item.guard_type],
      `${item.first_name} ${item.last_name}`,
    ];
    tableRows.push(tableData);
  });

  doc.text(` تقارير المستخدمين - ${names[type]} `, 190, 15, { align: "right" });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 25,
    styles: {
      font: "Amiri",
      fontSize: 12,
    },
    bodyStyles: { valign: "middle", halign: "right" },
    headStyles: { fillColor: [63, 81, 181], halign: "right" },
    tableLineWidth: 0.1,
    margin: { left: 10, right: 10 },
  });
  doc.save(`تقارير المستخدمين - ${names[type]}.pdf`);
};
export default function ExportUsers({ data, type }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const headers = [
    { label: "الاسم", key: "name" },
    { label: "نوع المستخدم", key: "type" },
    { label: "البريد الالكتروني", key: "mail" },
    { label: "الهاتف", key: "phone" },
  ];

  const [body, setBody] = useState([]);
  useEffect(() => {
    if (data) {
      let co = [];
      // eslint-disable-next-line array-callback-return
      data.map((i) => {
        co.push({
          name: `${i.first_name} ${i.last_name}`,
          type: types[i.guard_type],
          mail: i.email,
          phone: `=""${i.phone}""`,
        });
      });
      setBody(co);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <section className=" flex items-center justify-end ">
        <button
          onClick={handleClick}
          className=" btn2 flex items-center justify-center gap-2 "
        >
          تصدير <i class="fa-solid fa-caret-down" />
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <button
            onClick={() => {
              handleClose();
              generatePDF(data, type);
            }}
            className=" py-2 px-12 border-b flex items-center justify-center gap-2 "
          >
            PDF <i className="fa-solid fa-file-pdf"></i>
          </button>
          <button
            onClick={() => {
              handleClose();
            }}
            className=" "
          >
            <CSVLink
              data={body}
              headers={headers}
              filename={`تقارير المستخدمين - ${names[type]}.csv`}
              target="_blank"
              className=" w-full  py-2 px-12 border-b flex items-center justify-center gap-2 "
            >
              CVS <i className="fa-solid fa-table"></i>
            </CSVLink>
          </button>
        </Menu>
      </section>
    </div>
  );
}
