import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import usePostFormData from "../../../utils/usePostForm";
import { load } from "../../../utils/isLoadingStore";
import Loader from "../../../components/Loader/Loader";
import Cookies from "js-cookie";
import ImageCropper from "../../../components/pages/cropper/ImageCropper";
const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(file);
  });
};

export default function ChangeInfo({ close, show, set }) {
  const [file, setFile] = useState();
  const [croppedImage, setCroppedImage] = useState(null);
  const [logo, setLogo] = useState();
  const [croppedLogo, setCroppedLogo] = useState(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setFile(imageDataUrl); // Open cropper with selected image
    }
  };
  const handleLogoChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setLogo(imageDataUrl); // Open cropper with selected image
    }
  };
  const onCropComplete = (croppedBlob) => {
    // setCroppedImage(croppedImageUrl);
    // setFile(null); // Close cropper after cropping
    // Convert the Blob to a File
    const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
      type: "image/jpeg",
    });
    setCroppedImage(croppedFile);
    setFile(null); // Close cropper after cropping
  };
  const onCropCompleteLogo = (croppedBlob) => {
    const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
      type: "image/jpeg",
    });
    setCroppedLogo(croppedFile);
    setLogo(null); // Close cropper after cropping
  };
  const { postFormData, error, response } = usePostFormData(
    "camelOwner/Profile/update"
  );

  const validationSchema = Yup.object({
    firstName: Yup.string().required("الاسم الأول مطلوب"),
    lastName: Yup.string().required("الاسم الثاني مطلوب"),
    address: Yup.string().required("العنوان مطلوب"),
    camel_brand_name: Yup.string().required("الوسم مطلوب"),
    // about: Yup.string().required("النبذة عنك مطلوبة"),
  });

  const handleSubmit = async (values) => {
    const formdata = new FormData();
    formdata.append("first_name", values.firstName);
    formdata.append("last_name", values.lastName);
    formdata.append("address", values.address);
    formdata.append("bio", values.about);
    formdata.append("camel_brand_name", values.camel_brand_name);
    if (!!croppedImage) {
      formdata.append("image", croppedImage);
    }
    if (!!croppedLogo) {
      formdata.append("camel_brand_logo", croppedLogo);
    }
    await postFormData(formdata);
  };

  useEffect(() => {
    if (!!response) {
      set((pre) => ({
        ...pre,
        name: response.camel_owner.first_name,
        lastName: response.camel_owner.last_name,
        img: response.camel_owner.image,
        loc: response.camel_owner.address,
        desc: response.camel_owner.bio,
        camel_brand_name: response.camel_owner.camel_brand_name,
        camel_brand_logo: response.camel_owner.camel_brand_logo,
      }));
      Cookies.set(
        "name",
        `${response.camel_owner.first_name} ${response.camel_owner.last_name}`,
        {
          expires: 1,
        }
      );
      if (response.camel_owner.image) {
        Cookies.set("image", response.camel_owner.image, {
          expires: 1,
        });
      }

      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  const { isLoading } = useContext(load);

  return ReactDOM.createPortal(
    <div className="almarai-regular rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      <section className="max-h-[95vh] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] w-full rounded-[16px]">
        {isLoading && <Loader />}
        <section className="my-2 flex items-center justify-between">
          <h1 className="text-[#432315] text-[24px] font-[600]">
            تعديل معلومات عنك
          </h1>
          <button
            onClick={() => close(false)}
            className="text-red-600 font-[600]"
          >
            X
          </button>
        </section>

        <section>
          <h4 className="text-[#290900] text-[20px] font-[500] my-5">
            تغيير صورة الشخصية{" "}
          </h4>
          {croppedImage && (
            <div className="relative">
              <button
                onClick={() => setFile(null)}
                className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
              >
                {/* <i className="fa-solid fa-trash"></i> */}
              </button>
              <img
                alt="new cover"
                className="max-h-[230px] rounded-[20px] max-w-full mx-auto"
                // src={URL.createObjectURL(file)}
                src={URL.createObjectURL(croppedImage)}
              />
            </div>
          )}

          {!croppedImage && !file && (
            <div className="font-bold text-2xl p-8 flex items-center justify-center mx-auto bg-[#F5EFDC] rounded-[16px]">
              <section>
                <label className="cursor-pointer h-full w-full" htmlFor="file">
                  <section className="text-center">
                    <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#A59382] text-center"></i>
                    <h1 className="text-[#A59382]">رفع الصورة</h1>
                  </section>
                </label>
                <input
                  type="file"
                  id="file"
                  // onChange={(e) => {
                  //   setFile(e.target.files[0]);
                  // }}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </section>
            </div>
          )}

          {file && (
            <ImageCropper imageSrc={file} onCropComplete={onCropComplete} />
          )}
        </section>

        <hr className="my-5 bg-[#290900] border-[#290900]"></hr>

        <Formik
          initialValues={{
            firstName: show.name,
            lastName: show.lastName,
            address: show.loc,
            about: show.desc,
            camel_brand_name: show.camel_brand_name,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form className="grid grid-cols-1 lg:grid-cols-2 items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap">
              <section className="col-span-1">
                <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                  الاسم الاول
                </h1>
                <Field
                  name="firstName"
                  className="inpt"
                  type="text"
                  placeholder="الاسم الاول"
                />
                <ErrorMessage
                  name="firstName"
                  component="p"
                  className="text-red-500 text-sm mt-1"
                />
              </section>

              <section className="col-span-1">
                <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                  الاسم الثاني
                </h1>
                <Field
                  name="lastName"
                  className="inpt"
                  type="text"
                  placeholder="الاسم الثاني"
                />
                <ErrorMessage
                  name="lastName"
                  component="p"
                  className="text-red-500 text-sm mt-1"
                />
              </section>

              <section className="col-span-1 lg:col-span-2">
                <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                  العنوان
                </h1>
                <Field
                  name="address"
                  className="inpt"
                  type="text"
                  placeholder="العنوان"
                />
                <ErrorMessage
                  name="address"
                  component="p"
                  className="text-red-500 text-sm mt-1"
                />
              </section>

              <section className="col-span-1 lg:col-span-2">
                <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                  نبذة عنك
                </h1>
                <Field
                  as="textarea"
                  rows={3}
                  name="about"
                  className="inpt"
                  placeholder="نبذة عنك"
                />
                <ErrorMessage
                  name="about"
                  component="p"
                  className="text-red-500 text-sm mt-1"
                />
              </section>
              <section className="col-span-1 ">
                <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                  الوسم
                </h1>
                <Field
                  name="camel_brand_name"
                  className="inpt"
                  placeholder="نبذة عنك"
                />
                <ErrorMessage
                  name="camel_brand_name"
                  component="p"
                  className="text-red-500 text-sm mt-1"
                />
              </section>
              <section className="col-span-1 ">
                <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                  تغيير صورة الوسم
                </h1>
                {croppedLogo && (
                  <div className="relative">
                    <button
                      onClick={() => setCroppedLogo(null)}
                      className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                    >
                      <i className="fa-solid fa-trash"></i>
                    </button>
                    <img
                      alt="new cover"
                      className="max-h-[80px] rounded-full max-w-full "
                      // src={URL.createObjectURL(file)}
                      src={URL.createObjectURL(croppedLogo)}
                    />
                  </div>
                )}
                {!croppedLogo && !logo && (
                  <section className=" flex items-center gap-2 ">
                    <label>رفع صورة :</label>
                    <input
                      type="file"
                      id="file"
                      accept="image/*"
                      onChange={handleLogoChange}
                    />
                  </section>
                )}
                {logo && (
                  <ImageCropper
                    imageSrc={logo}
                    onCropComplete={onCropCompleteLogo}
                    w={600}
                    h={600}
                  />
                )}
              </section>

              <section className="col-span-1 lg:col-span-2 flex justify-end">
                <button type="submit" className="btn2">
                  حفظ
                </button>
              </section>
              {error && (
                <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
                  <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                    <i className="fa-solid fa-exclamation  "></i>
                  </span>
                  {!!error.response.data.message
                    ? error.response.data.message
                    : "  هناك مشكلة"}
                </section>
              )}
            </Form>
          )}
        </Formik>
      </section>
    </div>,
    document.body
  );
}
