import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { isLogged } from "../../../utils/inStore";
import usePostFormData from "../../../utils/usePostForm";
import { load } from "../../../utils/isLoadingStore";
import Loader from "../../../components/Loader/Loader";
import Cookies from "js-cookie";
import ImageCropper from "../../../components/pages/cropper/ImageCropper";
const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(file);
  });
};
export default function EditInfo({ close, data, re }) {
  const [file, setFile] = useState();
  const [croppedImage, setCroppedImage] = useState(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setFile(imageDataUrl); // Open cropper with selected image
    }
  };
  const onCropComplete = (croppedBlob) => {
    // setCroppedImage(croppedImageUrl);
    // setFile(null); // Close cropper after cropping
    // Convert the Blob to a File
    const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
      type: "image/jpeg",
    });
    setCroppedImage(croppedFile);
    setFile(null); // Close cropper after cropping
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("الاسم الأول مطلوب"),
    lastName: Yup.string().required("الاسم الثاني مطلوب"),
  });

  const { role } = useContext(isLogged);
  let link = "";
  if (role === "squer") {
    link = "field-owner/settings/update-info";
  }
  if (role === "provider") {
    link = "serviceProvider/settings/update-info";
  }
  if (role === "vistor") {
    link = "visitor/settings/update-info";
  }

  const { postFormData, error, response } = usePostFormData(link);
  const handleSubmit = async (values) => {
    const formdata = new FormData();
    formdata.append("first_name", values.firstName);
    formdata.append("last_name", values.lastName);
    formdata.append("address", data.address);
    formdata.append("phone", data.phone);
    // formdata.append("email", data.email);
    if (data.mail !== values.mail) {
      formdata.append("email", values.mail);
    }
    formdata.append("nationality", data.nationality);
    formdata.append("gender", data.gender);
    if (!!croppedImage) {
      formdata.append("photo", croppedImage);
    }
    await postFormData(formdata);
  };

  useEffect(() => {
    if (response) {
      if (role === "squer") {
        if (response) {
          if (response.field_owner) {
            if (
              response.field_owner.first_name &&
              response.field_owner.last_name
            ) {
              Cookies.set(
                "name",
                `${response.field_owner.first_name} ${response.field_owner.last_name}`,
                {
                  expires: 1,
                }
              );
              if (response.field_owner.photo || response.field_owner.image) {
                Cookies.set(
                  "image",
                  response.field_owner.photo || response.field_owner.image,
                  {
                    expires: 1,
                  }
                );
              }
            }
          }
        }
      }
      if (role === "provider") {
        if (response) {
          if (response.service_providers) {
            if (
              response.service_providers.first_name &&
              response.service_providers.last_name
            ) {
              Cookies.set(
                "name",
                `${response.service_providers.first_name} ${response.service_providers.last_name}`,
                {
                  expires: 1,
                }
              );
              if (
                response.service_providers.photo ||
                response.service_providers.image
              ) {
                Cookies.set(
                  "image",
                  response.service_providers.photo ||
                    response.service_providers.image,
                  {
                    expires: 1,
                  }
                );
              }
            }
          }
        }
      }
      if (role === "vistor") {
        if (response) {
          if (response.visitor) {
            if (response.visitor.first_name && response.visitor.last_name) {
              Cookies.set(
                "name",
                `${response.visitor.first_name} ${response.visitor.last_name}`,
                {
                  expires: 1,
                }
              );
              if (response.visitor.photo || response.visitor.image) {
                Cookies.set(
                  "image",
                  response.visitor.photo || response.visitor.image,
                  {
                    expires: 1,
                  }
                );
              }
            }
          }
        }
      }

      close();
      re();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  const { isLoading } = useContext(load);
  return ReactDOM.createPortal(
    <div className="almarai-regular rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] w-full rounded-[16px]">
        <section className="my-2 flex items-center justify-between">
          <h1 className="text-[#432315] text-[24px] font-[600]">
            معلومات شخصية
          </h1>
          <button
            onClick={() => close(false)}
            className="text-red-600 font-[600]"
          >
            X
          </button>
        </section>

        <Formik
          initialValues={{
            firstName: data.first_name,
            lastName: data.last_name,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <>
              <Form className="grid grid-cols-1 lg:grid-cols-2 items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap">
                <section className="col-span-1">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    الاسم الاول
                  </h1>
                  <Field
                    name="firstName"
                    className="inpt"
                    type="text"
                    placeholder="الاسم الاول"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </section>

                <section className="col-span-1">
                  <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                    الاسم الثاني
                  </h1>
                  <Field
                    name="lastName"
                    className="inpt"
                    type="text"
                    placeholder="الاسم الثاني"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="p"
                    className="text-red-500 text-sm mt-1"
                  />
                </section>
                <hr className="my-5 col-span-1 lg:col-span-2 bg-[#290900] border-[#290900]"></hr>
                <section className=" col-span-1 lg:col-span-2 ">
                  <h4 className="text-[#290900] text-[20px] font-[500] my-5">
                    صورة الشخصية
                  </h4>
                  {!croppedImage && !file && (
                    <div className="font-bold text-2xl p-8 flex items-center justify-center mx-auto bg-[#F5EFDC] rounded-[16px]">
                      <section>
                        <label
                          className="cursor-pointer h-full w-full"
                          htmlFor="file"
                        >
                          <section className="text-center">
                            <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#A59382] text-center"></i>
                            <h1 className="text-[#A59382]">رفع الصورة</h1>
                          </section>
                        </label>
                        <input
                          type="file"
                          id="file"
                          // onChange={(e) => {
                          //   setFile(e.target.files[0]);
                          // }}
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                      </section>
                    </div>
                  )}
                  {croppedImage && (
                    <div className="relative">
                      <button
                        onClick={() => setFile(null)}
                        className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                      >
                        {/* <i className="fa-solid fa-trash"></i> */}
                      </button>
                      <img
                        alt="new cover"
                        className="max-h-[220px] rounded-full max-w-full mx-auto"
                        // src={URL.createObjectURL(file)}
                        src={URL.createObjectURL(croppedImage)}
                      />
                    </div>
                  )}{" "}
                  {file && (
                    <ImageCropper
                      imageSrc={file}
                      onCropComplete={onCropComplete}
                      w={400}
                      h={400}
                    />
                  )}
                </section>

                <section className="col-span-1 lg:col-span-2 flex justify-end">
                  <button type="submit" className="btn2">
                    حفظ
                  </button>
                </section>
              </Form>
              {error && (
                <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
                  <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                    <i className="fa-solid fa-exclamation  "></i>
                  </span>
                  {!!error.response.data.message
                    ? error.response.data.message
                    : "  هناك مشكلة"}
                </section>
              )}
            </>
          )}
        </Formik>
      </section>
    </div>,
    document.body
  );
}
