import React, { useContext, useEffect, useState } from "react";
import { isLogged } from "../../../../utils/inStore";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import { load } from "../../../../utils/isLoadingStore";
import usePostFormData from "../../../../utils/usePostForm";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loader from "../../../../components/Loader/Loader";
import ReactDOM from "react-dom";
import ImageCropper from "../../../../components/pages/cropper/ImageCropper";
const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(file);
  });
};
export default function EditCart({ alldata, close, reload }) {
  const [form, setForm] = useState({
    specialization_id: "",
    bio: "",
    about_doctor: "",
    facebook: "",
    twitter: "",
    whatsapp: "",
    instagram: "",
    doctor_card_image: "",
    location: "",
  });
  const [croppedImage, setCroppedImage] = useState(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setForm({ ...form, doctor_card_image: imageDataUrl });
    }
  };
  const onCropComplete = (croppedBlob) => {
    // setCroppedImage(croppedImageUrl);
    // setFile(null); // Close cropper after cropping
    // Convert the Blob to a File
    const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
      type: "image/jpeg",
    });
    setCroppedImage(croppedFile);
    setForm({ ...form, doctor_card_image: null }); // Close cropper after cropping
  };
  const [details, setDetails] = useState("");
  useEffect(() => {
    setDetails(alldata.about_doctor);
    setForm({
      specialization_id: alldata.specialization_id,
      bio: alldata.bio,
      about_doctor: alldata.about_doctor,
      facebook: alldata.facebook,
      twitter: alldata.twitter,
      whatsapp: alldata.whatsapp,
      instagram: alldata.instagram,
      doctor_card_image: "",
      location: alldata.location,
    });
  }, [alldata]);
  const { role } = useContext(isLogged);
  let link = "";
  let linkToFetch = "";
  if (role === "provider") {
    link = "serviceProvider/veterinaryMedicine/doctor_cards/update";
    linkToFetch = "serviceProvider/veterinaryMedicine/specialization/all";
  }
  const { data } = useFetchWithToken(linkToFetch);
  const [specs, setSpecs] = useState([]);
  useEffect(() => {
    if (!!data) {
      setSpecs(data);
    }
  }, [data, role]);

  const handelChanege = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handelChanegeDetails = (value) => {
    setDetails(value);
  };
  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};
    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const { postFormData, error, response } = usePostFormData(link);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formdata = new FormData();
      formdata.append("specialization_id", form.specialization_id);
      formdata.append("bio", form.bio);
      formdata.append("about_doctor", details);
      if (!!croppedImage) {
        formdata.append("doctor_card_image", croppedImage);
      }
      if (!!form.instagram) {
        formdata.append("instagram", form.instagram);
      }
      if (!!form.whatsapp) {
        formdata.append("whatsapp", form.whatsapp);
      }
      if (!!form.twitter) {
        formdata.append("twitter", form.twitter);
      }
      if (!!form.facebook) {
        formdata.append("facebook", form.facebook);
      }

      formdata.append("location", form.location);

      await postFormData(formdata);
    }
  };
  useEffect(() => {
    if (!!response) {
      reload(response);
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const { isLoading } = useContext(load);
  // console.log(croppedImage);

  return ReactDOM.createPortal(
    <div className="almarai-regular text-center rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] w-full max-w-[1100px] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] rounded-[16px]">
        <h1 className=" text-xl my-2 ">تعديل الكارت </h1>
        <form className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap  ">
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              تعديل الصورة المصغرة
            </h1>
            {croppedImage && (
              <div className="relative">
                <button
                  onClick={() => setCroppedImage(null)}
                  className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
                <img
                  alt="new cover"
                  className="max-h-[230px] rounded-[20px] max-w-full mx-auto"
                  // src={URL.createObjectURL(file)}
                  src={URL.createObjectURL(croppedImage)}
                />
              </div>
            )}
            {!croppedImage && !form.doctor_card_image && (
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  // onChange={(e) => {
                  //   setForm({ ...form, card_image: e.target.files[0] });
                  // }}
                  onChange={handleFileChange}
                />
              </section>
            )}
            {form.doctor_card_image && (
              <ImageCropper
                imageSrc={form.doctor_card_image}
                onCropComplete={onCropComplete}
                w={300}
                h={180}
              />
            )}
            {<p className="error">{errors.doctor_card_image}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              العنوان
            </h1>
            <input
              name="location"
              value={form.location}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" العنوان"
              required
            />
            {<p className="error">{errors.location}</p>}
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              التخصص
            </h1>
            <select
              className="inpt"
              name="specialization_id"
              value={form.specialization_id}
              onChange={handelChanege}
            >
              <option hidden>التخصصات</option>
              {specs.map((e) => (
                <option value={e.id} key={e.id}>
                  {e.name}
                </option>
              ))}
            </select>

            {<p className="error">{errors.specialization_id}</p>}
          </section>
          <section className=" col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              نبذة عن الطبيب
            </h1>
            <textarea
              name="bio"
              cols={2}
              value={form.bio}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder=" نبذة عن الطبيب "
              required
            />
            {<p className="error">{errors.bio}</p>}
          </section>
          <section className=" min-h-[300px] col-span-1 lg:col-span-2 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              عن الطبيب
            </h1>
            <ReactQuill
              value={details}
              onChange={handelChanegeDetails}
              className="text-editor  h-[200px]  rtl"
              style={{ direction: "rtl", textAlign: "right" }}
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رابط الفيسبوك
            </h1>
            <input
              name="facebook"
              value={form.facebook}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="  رابط الفيسبوك"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رابط التويتر
            </h1>
            <input
              name="twitter"
              value={form.twitter}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="  رابط التويتر"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رابط الواتس اب
            </h1>
            <input
              name="whatsapp"
              value={form.whatsapp}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="  رابط الواتس اب"
              required
            />
          </section>
          <section className=" col-span-1 ">
            <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
              رابط الانستجرام
            </h1>
            <input
              name="instagram"
              value={form.instagram}
              onChange={handelChanege}
              className=" inpt "
              type="text"
              placeholder="رابط الانستجرام"
              required
            />
          </section>
        </form>
        <section className=" my-5 flex items-center justify-center gap-3 flex-wrap ">
          <button onClick={handleSubmit} className="btn9">
            تعديل
          </button>

          <button onClick={() => close(false)} className="btn8">
            الغاء
          </button>
        </section>
        {error && (
          <section className=" flex my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
            <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
              <i className="fa-solid fa-exclamation  "></i>
            </span>
            {!!error.response ? (
              <>
                {!!error.response.data ? (
                  <>
                    {!!error.response.data.error
                      ? error.response.data.error
                      : "  هناك مشكلة"}
                  </>
                ) : (
                  <>هناك مشكلة</>
                )}
              </>
            ) : (
              <>هناك مشكلة</>
            )}
          </section>
        )}
      </section>
    </div>,
    document.body
  );
}
