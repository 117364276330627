import React, { createContext, useState } from "react";

export const openMessage = createContext();
export default function OpenMessageStore({ children }) {
  const [openMessageState, setOpenMessageState] = useState(false);
  return (
    <openMessage.Provider value={{ openMessageState, setOpenMessageState }}>
      {children}
    </openMessage.Provider>
  );
}
