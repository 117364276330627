import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoginLayout from "../Layout";
import SelectBtn2 from "../../../components/pages/SelectBtn2";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { isLogged } from "../../../utils/inStore";
import { getToken } from "firebase/messaging";
import { messaging } from "../../../notification/firebase";
import { openMessage } from "../../../utils/openMessageStore";

export default function Login() {
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const { setLogged, setRole } = useContext(isLogged);
  const { setOpenMessageState } = useContext(openMessage);
  // const [tokn, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   // Request permission to send notifications

  //   const requestPermission = async () => {
  //     try {
  //       const token = await getToken(messaging, {
  //         vapidKey:
  //           "BKcf-FhABEHIpBpDUJv8GWPjFztPDEVQd-HYitFxmhcwaT4d1dsCh8X45k7PFhFaNFM-hRWS2JENB2ErXz8vyks",
  //       });
  //       if (token) {
  //         // console.log("FCM Token:", token);
  //         setToken(token);
  //         // Send the token to your backend for notifications
  //       } else {
  //         console.log("No registration token available.");
  //       }
  //     } catch (error) {
  //       console.error("Error getting FCM token:", error);
  //     }
  //   };

  //   requestPermission();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [Notification.permission]);

  const navigation = useNavigate();
  const saveUserData = (data, role) => {
    Cookies.set("token", data.token, {
      // secure: true,
      // sameSite: "Strict",
      expires: 1,
    });
    Cookies.set("name", `${data.user.first_name} ${data.user.last_name}`, {
      expires: 1,
    });
    if (data.role === "service_provider") {
      Cookies.set("type", data.user.type, { expires: 1 });
    }
    if (data.user.image || data.user.photo) {
      Cookies.set("image", data.user.image || data.user.photo, { expires: 1 });
    } else {
      Cookies.set("image", null, { expires: 1 });
    }

    Cookies.set("value", data.user.id, { expires: 1 });
    Cookies.set("rcom9lsuZsWree9mdXNlcg==", role.val, { expires: 1 });
    const thisTime = new Date().getTime();
    Cookies.set("expirein", thisTime + 24 * 60 * 60 * 1000, {
      expires: 1,
    });
    setLogged(true);
    setRole(role.name);
    if (data.user.image || data.user.photo) {
      navigation("/");
    } else {
      if (data.role === "super_admin") {
        navigation("/");
      } else {
        setOpenMessageState(true);
        if (data.role === "camel_owner") {
          navigation("/profile");
        } else {
          navigation("/settings");
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      mail: "",
      password: "",
      // type: "visitor",
      type: "super_admin",
      serverType: "veterinary",
    },
    validationSchema: Yup.object({
      mail: Yup.string()
        .email("البريد الإلكتروني غير صالح")
        .required("البريد الإلكتروني مطلوب"),
      password: Yup.string()
        .min(6, "كلمة المرور يجب أن تحتوي على 6 أحرف على الأقل")
        .required("كلمة المرور مطلوبة"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);
      setTimeout(async () => {
        let body = {};
        let link = "";
        let tokk = null;
        try {
          const token = await getToken(messaging, {
            vapidKey:
              "BKcf-FhABEHIpBpDUJv8GWPjFztPDEVQd-HYitFxmhcwaT4d1dsCh8X45k7PFhFaNFM-hRWS2JENB2ErXz8vyks",
          });
          if (token) {
            // console.log( token);
            tokk = token;

          } else {
            console.log("No registration token available.");
          }
        } catch (error) {
          console.error("Error getting FCM token:", error);
        }
        if (values.type === "service_provider") {
          body = {
            email: values.mail,
            password: values.password,
            role: values.type,
            type: values.serverType,
            firebase_token: tokk,
          };

          link = `${process.env.REACT_APP_URL}/api/global/serviceProvider/login`;
        } else {
          body = {
            email: values.mail,
            password: values.password,
            role: values.type,
            firebase_token: tokk,
            // firebase_token: tokn,
          };
          link = `${process.env.REACT_APP_URL}/api/login`;
        }

        // console.log(body);

        try {
          // setSubmitting(true);

          const res = await fetch(link, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
            // body: JSON.stringify({
            //   email: values.mail,
            //   password: values.password,
            //   role: values.type,
            // }),
          });

          if (res.status === 200) {
            const data = await res.json();
            const roles = {
              visitor: { name: "vistor", val: "dmlzaXRvcnRvc2VlMDk4" },
              camel_owner: {
                name: "camel-owner",
                val: "aWFtb3duaW5nY2FtZWxzMzA2NA==",
              },
              field_owner: {
                name: "squer",
                val: "c3F1ZXJvd25lcmhhdmVzb21lZXZlbnRzMjA4",
              },
              service_provider: {
                name: "provider",
                val: "SXByb3ZpZHNvbWVzZXJ2aWNlc3lvdWNhbnRlc3RpdDM",
              },
              super_admin: {
                name: "super_admin",
                val: "c3VwZXJBttZG1pbiB0ZXh0IHRvIGNvZGUxxgNjkgdHlwZSAmmyMTM1",
              },
            };

            saveUserData(data, roles[values.type]);
          } else {
            setErr(true);
            res.json().then((data) => {
              if (data.message === "Account not approved") {
                setErrMsg("هذا الحساب لم يتم الموافقة عليه بعد");
              }
              // if (data.message === "Account not approved") {
              //   setErrMsg("هذا الحساب تم رفضه");
              // }
            });
            setTimeout(() => {
              setErr(false);
              setErrMsg(null);
            }, 3000);
          }
        } catch (error) {
          console.error("Login failed:", error);
          setErr(true);
        } finally {
          setSubmitting(false);
          setIsLoading(false);
        }
      }, 500);
    },
  });

  const { values, handleChange, handleSubmit, errors, touched } = formik;

  return (
    <LoginLayout>
      <div className="w-full relative ">
        <Link
          className=" md:absolute flex gap-1 items-center top-2 right-2 "
          to="/"
        >
          <i className="fa-solid fa-arrow-right"></i>
          <button className="  ">الصفحة الرئيسية</button>
        </Link>
        <section className="px-6 md:px-12 lg:px-24 w-full">
          <h1 className="text-center text-[25px] md:text-[30px] font-[700] text-[#290900] my-2">
            تسجيل الدخول
          </h1>
          <h1 className="text-center text-[12px] md:text-[18px] font-[400] text-[#290900] my-1">
            قم بتسجيل الدخول للوصول الى حسابك في منصة الإبل
          </h1>

          {Notification.permission !== "granted" && (
            <h1 className="text-center text-[8px] md:text-[15px] underline  font-[600] text-[#290900] my-1">
              الرجاء تفعيل الاشعارات لتجربة اكثر فعالية
            </h1>
          )}
          {/* {tokn} */}
          <div className="my-5 w-full">
            <section className="my-2">
              <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                البريد الإلكتروني
              </h1>
              <input
                name="mail"
                value={values.mail}
                onChange={handleChange}
                className={`ltr w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                  errors.mail && touched.mail ? "border-red-500" : ""
                }`}
                type="email"
                placeholder="البريد الإلكتروني"
              />
              {errors.mail && touched.mail && (
                <div className="text-red-500 text-sm">{errors.mail}</div>
              )}
            </section>

            <section className="my-2">
              <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                كلمة السر
              </h1>
              <input
                name="password"
                value={values.password}
                onChange={handleChange}
                className={`ltr w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                  errors.password && touched.password ? "border-red-500" : ""
                }`}
                type="password"
                placeholder="كلمة السر"
              />
              {errors.password && touched.password && (
                <div className="text-red-500 text-sm">{errors.password}</div>
              )}
            </section>

            <h4 className="text-center text-[18px] font-[400] text-[#290900] my-1">
              تسجيل ك:
            </h4>

            <section className="flex items-center gap-1 justify-between flex-wrap">
              <SelectBtn2
                // dis={isSubmitting}
                dis={isLoading}
                name="type"
                handelVal={handleChange}
                text="زائر"
                val="visitor"
                mainval={values.type}
              />
              <SelectBtn2
                dis={isLoading}
                name="type"
                handelVal={handleChange}
                text="مالك ابل"
                val="camel_owner"
                mainval={values.type}
              />
              <SelectBtn2
                dis={isLoading}
                name="type"
                handelVal={handleChange}
                text="الساحة"
                val="field_owner"
                mainval={values.type}
              />
              <SelectBtn2
                dis={isLoading}
                name="type"
                handelVal={handleChange}
                text="مزود خدمة"
                val="service_provider"
                mainval={values.type}
              />
            </section>
            {values.type === "service_provider" && (
              <section className=" mt-2 flex items-center gap-1 justify-between flex-wrap">
                <SelectBtn2
                  dis={isLoading}
                  name="serverType"
                  handelVal={handleChange}
                  text="طب بيطري"
                  val="veterinary"
                  mainval={values.serverType}
                />
                <SelectBtn2
                  dis={isLoading}
                  name="serverType"
                  handelVal={handleChange}
                  text="مستقل"
                  val="freelancer"
                  mainval={values.serverType}
                />
                <SelectBtn2
                  dis={isLoading}
                  name="serverType"
                  handelVal={handleChange}
                  text="خبير"
                  val="expert"
                  mainval={values.serverType}
                />
              </section>
            )}

            <section className="my-5 flex items-center justify-between">
              {/* <Link to="" className="text-[14px] font-[400] text-[#432315]">
                نسيت كلمة السر؟
              </Link> */}
            </section>
            {err && (
              <section className=" my-2 p-2 text-white rounded-[8px] bg-red-500  ">
                {errMsg
                  ? errMsg
                  : "كلمة السر او البريد الالكتروني او نوع المستخدم خطأ"}
              </section>
            )}

            <button
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading}
              // disabled={isSubmitting}
              className="w-full text-[#FFFCF7] text-[20px] font-[600] bg-[#432315] rounded-[8px] h-[50px]"
            >
              {/* {isSubmitting ? "جاري تسجيل الدخول..." : "تسجيل الدخول"} */}
              {isLoading ? "جاري تسجيل الدخول..." : "تسجيل الدخول"}
            </button>

            <section className=" relative text-center my-3 w-full ">
              <hr className=" z-0 w-full absolute top-[12px] " />
              <Link to="/register">
                <h1 className="relative z-10 text-[#290900]/50 font-[400] text-[16px] px-2 bg-[#fff] w-fit mx-auto ">
                  ليس لديك حساب؟ انشاء حساب
                </h1>
              </Link>
            </section>
          </div>
        </section>
      </div>
    </LoginLayout>
  );
}
