import React from "react";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import ExportReports from "./ExportReports";
import ExportSubs from "./ExportSubs";

export default function Reports({ back }) {
  let link = "global/Report/field/owner/detailed-report";
  const { data, error } = useFetchWithToken(link);
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <section>
        <button onClick={back} className=" text-[18px] ">
          <i className="fa-solid fa-angle-right ml-2 "></i>الرجوع
        </button>
      </section>
      {data && (
        <div>
          {data.report && (
            <div>
              {data.report.fields && (
                <>
                  <ExportReports data={data.report.fields} />
                  {data.report.fields.map((filed, inx) => (
                    <div
                      className=" my-2 border border-black/70 p-2 rounded-[16px] "
                      key={inx}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>اسم الساحة</th>
                            <th>مساحة الساحة</th>
                            <th>سعر الاشتراك</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className=" text-center ">
                              {filed.field_name}
                            </td>
                            <td className=" text-center ">{filed.space}</td>
                            <td className=" text-center ">
                              {filed.subscription_price} ر.س
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <h1 className=" text-center my-3 font-bold text-xl  ">
                        المشتركين
                      </h1>
                      {filed.subscribers.length === 0 ? (
                        <h1 className=" text-red-600 text-center my-3 font-bold text-lg  ">
                          لا يوجد مشتركين
                        </h1>
                      ) : (
                        <>
                          <ExportSubs
                            data={filed.subscribers}
                            name={filed.field_name}
                          />
                          <table>
                            <thead>
                              <tr>
                                <th>الاسم</th>
                                <th>رقم الهاتف</th>
                                <th>البريد الاكتروني</th>
                                {/* <th>نوع المستخدم</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {filed.subscribers.map((subs, ind) => (
                                <tr key={ind}>
                                  <td className=" text-center ">{subs.name}</td>
                                  <td className=" text-center ">
                                    {subs.phone}
                                  </td>
                                  <td className=" text-center ">
                                    {subs.email}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
