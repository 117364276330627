import React, { useContext } from "react";
import { isLogged } from "../../../../utils/inStore";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import ExportsSubs from "./ExportsSubs";

export default function Attends({ back, id }) {
  const { role } = useContext(isLogged);
  let link = "";
  if (role === "camel-owner") {
    link = `camelOwner/event/subscribers/${id}/camel_owner`;
  }
  if (role === "squer") {
    link = `field-owner/event/subscribers/${id}/field_owner`;
  }
  if (role === "super_admin") {
    link = `superAdmin/event/subscribers/${id}/super_admin`;
  }
  const { data, error } = useFetchWithToken(link);
  console.log(data)

  const roles = {
    camel_owner: "مالك ابل",
    field_owner: "صاحب ساحة",
    service_provider: "مقدم خدمة",
    visitor: "زائر",
    super_admin: "ادارة",
  };
  return (
    <div>
      <section>
        <button onClick={() => back()} className=" text-[18px] ">
          <i className="fa-solid fa-angle-right ml-2 "></i>الرجوع
        </button>
      </section>
      <section>
        {error && (
          <h1 className=" text-red-600 text-xl font-bold text-center ">
            هناك مشكلة
          </h1>
        )}
        {data && (
          <>
            <ExportsSubs data={data.subscribers} name={data.event} />
            {data.subscribers ? (
              <table>
                <thead>
                  <tr className=" grid grid-cols-4 ">
                    <th>الاسم</th>
                    <th>نوع المستخدم</th>
                    <th>رقم الهاتف</th>
                    <th>الايميل</th>
                  </tr>
                </thead>
                <tbody>
                  {data.subscribers.map((e, inx) => (
                    <tr className=" grid grid-cols-4 text-center " key={inx}>
                      <td>{e.name}</td>
                      <td>{roles[e.role]}</td>
                      <td>{e.phone}</td>
                      <td>{e.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h1 className=" text-red-600 text-xl font-bold text-center ">
                لا توجد اشتراكات حتي الان
              </h1>
            )}
          </>
        )}
      </section>
    </div>
  );
}
