import { Menu } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import font from "../../../../../utils/Amiri-Regular-normal";

const types = {
  camel_owner: "مالك ابل",
  field_owner: "مالك ساحة",
  service_provider: "مقدم خدمة",
  Visitor: "زائر",
};

const generatePDF = (data, reports, type) => {
  const doc = new jsPDF();

  doc.addFileToVFS("Amiri-Regular.ttf", font);
  doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
  doc.setFont("Amiri");
  doc.setFontSize(12);

  const tableColumn = ["صافي الارباح", "نسبة المنصة", "اسم الاستثمار"];
  const tableRows = [];

  data.forEach((item, index) => {
    const tableData = [
      `ر.س ${reports[item].revenue_after_platformFee}`,
      `${reports[item].platformFee} ر.س`,
      reports[item].investment_title,
    ];
    tableRows.push(tableData);
  });

  doc.text(`تقارير الاستثمار - ${types[type]} `, 190, 15, { align: "right" });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 25,
    styles: {
      font: "Amiri",
      fontSize: 12,
    },
    bodyStyles: { valign: "middle", halign: "right" },
    headStyles: { fillColor: [63, 81, 181], halign: "right" },
    tableLineWidth: 0.1,
    margin: { left: 10, right: 10 },
  });
  doc.save(`تقارير الاستثمار - ${types[type]}.pdf`);
};

export default function ExportReports({ data, reports, type }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const headers = [
    { label: "اسم الاستثمار", key: "name" },
    { label: "الارباح بدون النسبة", key: "before" },
    { label: "نسبة المنصة", key: "fees" },
    { label: "صافي الارباح", key: "after" },
  ];

  const [body, setBody] = useState([]);
  useEffect(() => {
    if (data) {
      let co = [];
      // eslint-disable-next-line array-callback-return
      data.map((item) => {
        co.push({
          name: reports[item].investment_title,
          before: reports[item].revenue_before_platformFee,
          fees: reports[item].platformFee,
          after: reports[item].revenue_after_platformFee,
        });
      });
      setBody(co);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div>
      <section className=" my-2 flex items-center justify-end ">
        <button
          onClick={handleClick}
          className=" btn2 flex items-center justify-center gap-2 "
        >
          تصدير <i class="fa-solid fa-caret-down" />
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <button
            onClick={() => {
              handleClose();
              generatePDF(data, reports, type);
            }}
            className=" py-2 px-12 border-b flex items-center justify-center gap-2 "
          >
            PDF <i className="fa-solid fa-file-pdf"></i>
          </button>
          <button
            onClick={() => {
              handleClose();
            }}
            className=" "
          >
            <CSVLink
              data={body}
              headers={headers}
              filename={`تقارير الاستثمار - ${types[type]}.csv`}
              target="_blank"
              className=" w-full  py-2 px-12 border-b flex items-center justify-center gap-2 "
            >
              CVS <i className="fa-solid fa-table"></i>
            </CSVLink>
          </button>
        </Menu>
      </section>
    </div>
  );
}
