import React, { useEffect, useState } from "react";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import usePostWithToken from "../../../../utils/usePostJson";

export default function HomePageContent() {
  const [about, setAbout] = useState("");
  const [services, setServices] = useState([]);
  let link = "global/vision";
  const { data } = useFetchWithReload(link);
  useEffect(() => {
    if (data) {
      //   setVision(data.vision.description);
      let fil = data.home_page_contents.filter((i) => i.key === "services");
      if (fil.length > 0) {
        setAbout(fil[0].content);
        setServices(fil[0].services);
      }
    }
  }, [data]);
  const handleChangeAgenda = (e, inx) => {
    const updatedAgenda = services.map((item, index) =>
      index === inx ? e.target.value : item
    );

    setServices(updatedAgenda);
  };
  const { postData, response: add } = usePostWithToken(
    `superAdmin/homePage/update/1`
  );
  const updateHandeller = () => {
    postData({
      key: "services",
      title: "الخدمات",
      content: about,
      services: services,
    });
  };
  return (
    <div>
      <div className="bg-[#E8DCC0] rounded-[15px] p-4 mx-auto max-w-[1100px]">
        <h1 className=" text-xl my-4 text-center font-bold ">
          الجملة الترحيبية
        </h1>
        <textarea
          rows={2}
          name="vision"
          value={about}
          onChange={(e) => setAbout(e.target.value)}
          className=" inpt "
          type="text"
          placeholder="  الجملة الترحيبية"
          required
        />
        <h1 className=" text-xl my-4 text-center font-bold ">الخدمات</h1>
        {services.map((i, inx) => (
          <section key={inx} className=" border-b   ">
            <button
              onClick={() => {
                let newAgenda = services.filter((e, index) => inx !== index);
                setServices(newAgenda);
              }}
            >
              <i className="fa-solid fa-trash text-red-600"></i>
            </button>
            <input
              name="title"
              value={i}
              onChange={(e) => handleChangeAgenda(e, inx)}
              className=" inpt "
              type="text"
              placeholder="الخدمة"
              required
            />
          </section>
        ))}
        <section className=" text-center ">
          <button
            onClick={() => setServices([...services, ""])}
            className="btn2 my-3"
          >
            اضافة خدمة
          </button>
        </section>
        <section className=" my-4 text-center ">
          <button onClick={updateHandeller} className=" btn2 ">
            تعديل
          </button>
          {add && (
            <section className=" p-2 rounded-md bg-green-600 text-white text-xl flex items-center gap-2 my-2 ">
              <i class="fa-solid fa-circle-check"></i>
              <h1>تم تغيير البيانات بنجاح</h1>
            </section>
          )}
        </section>
      </div>
    </div>
  );
}
