import React from "react";
import Hr from "../../../components/pages/Hr";
import useFetchWithReload from "../../../utils/useGetWithReload";
import { Link } from "react-router-dom";
import RelatedComponent from "./RelatedComponent";

export default function RelatedSection() {
  const { data } = useFetchWithReload(`global/related_sites`);
  return (
    <div>
      {data && (
        <>
          {data.length > 0 && (
            <section className=" p-4 md:p-12  text-center ">
              <>
                <h1 className=" text-[#432315] text-[36px] font-[600] my-2 ">
                  مواقع ذات صلة
                </h1>
                <Hr color="#432315" />
              </>
            </section>
          )}
          <section className=" my-6 flex items-start justify-center gap-8 flex-wrap ">
            {data.slice(0, 4).map((i, inx) => (
              <RelatedComponent key={inx} data={i} />
            ))}
          </section>
          <section className=" text-center ">
            {data.length > 0 && (
              <Link className=" btn2 " to="/related-platforms">
                عرض الكل
              </Link>
            )}
          </section>
        </>
      )}
    </div>
  );
}
