import React, { useState } from "react";

export default function Profile({ data }) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div className="    relative   ">
        {!!data.cover_image ? (
          <div className="  top-0 left-0 bg-origin-content bg-cover   w-full h-full">
            <img
              src={`${process.env.REACT_APP_URL}/${data.cover_image}`}
              alt="Vision"
              className=" left-0 top-0 h-full min-h-[190px] w-full object-cover"
              // className=" left-0 top-0 h-full min-h-[150px] w-full"
            />
          </div>
        ) : (
          <div className=" h-[180px] md:h-[280px]  py-12 px-8 md:px-16 ">
            <div className=" bg-[#F5EFDC] flex items-center justify-center absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full">
              <section>
                {" "}
                <img
                  src="./imgs/navLogoCropped.png"
                  className=" w-[250px] opacity-45 "
                  alt="Logo"
                />{" "}
              </section>
            </div>
          </div>
        )}
      </div>
      {/* <div className=" h-[180px] md:h-[280px]  py-12 px-8 md:px-16 relative   ">
        {!!data.cover_image ? (
          <div className=" absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full">
            <img
              src={`${process.env.REACT_APP_URL}/${data.cover_image}`}
              alt="Vision"
              className="absolute left-0 top-0 h-full w-full object-cover"
            />
          </div>
        ) : (
          <div className=" bg-[#F5EFDC] flex items-center justify-center absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full">
            <section>
              {" "}
              <img
                src="./imgs/navLogoCropped.png"
                className=" w-[250px] opacity-45 "
                alt="Logo"
              />{" "}
            </section>
          </div>
        )}
      </div> */}
      <div className=" md:flex  flex-row-reverse items-start gap-12 lg:gap-24  bg-[#E8DCC0] py-8 px-8 lg:px-24 ">
        <section className=" max-w-[290px]  mx-auto  mb-8 md:w-fit  mt-[-108px] md:mt-[-132px] z-10 relative  ">
          {data.image ? (
            <img
              src={`${process.env.REACT_APP_URL}/${data.image}`}
              alt="user profile"
              className="mx-auto h-[120px] md:h-[150px]    rounded-[20px]  "
            />
          ) : (
            <div className="  mx-auto w-full ">
              <section className="mx-auto  h-[150px] w-[150px]  md:h-[200px] md:w-[200px] rounded-full bg-gray-200  flex items-center justify-center ">
                {" "}
                <i className="fa-solid fa-user  text-[#432315]/70 text-4xl md:text-8xl "></i>
              </section>
            </div>
          )}

          <h1 className="  text-center text-[#432315] font-sans text-[20px] lg:text-[36px] font-[600]  md:my-4 ">
            {data.first_name} {data.last_name}
          </h1>
          <h1 className=" text-center flex items-center justify-center text-[#432315] text-[12px] lg:text-[18px] ">
            <i className=" fa-solid fa-location-dot " />
            <h1 className=" mx-3 ">{data.address}</h1>
          </h1>
        </section>

        <section className=" flex-1 ">
          <section className=" flex items-center gap-2 flex-wrap ">
            <section className=" flex items-center ">
              {data.camel_brand_logo && (
                <img
                  src={`${process.env.REACT_APP_URL}/${data.camel_brand_logo}`}
                  alt="صورة الوسم"
                  className=" relative z-10 w-[70px] h-[70px] rounded-full "
                />
              )}
              {data.camel_brand_name && (
                <section className=" flex items-center gap-5 relative z-0 my-4 bg-[#432315] pl-4 pr-10 mr-[-29px] text-[#E8DCC0] text-[18px] py-1 font-[600] text-center rounded-[10px] border border-[#432315] ">
                  {data.camel_brand_name}
                  <button
                    onClick={() => setOpen(true)}
                    className=" text-[12px] "
                  >
                    <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
                  </button>
                </section>
              )}
            </section>
            <section className=" my-4 text-[#432315] text-[18px] py-1 font-[600] text-center rounded-[10px] border border-[#432315] w-[150px] ">
              مالك الابل
            </section>
          </section>

          <p className=" text-[#432315] text-[18px] font-[700] leading-[35px] ">
            {data.bio}
          </p>
        </section>
      </div>
      {open && (
        <div className=" fixed h-screen w-full top-0 left-0 bg-[black]/30 flex items-center justify-center p-4 z-40 ">
          <div className=" w-full max-w-[600px] text-center ">
            {data.camel_brand_logo && (
              <img
                src={`${process.env.REACT_APP_URL}/${data.camel_brand_logo}`}
                alt="صورة الوسم"
                className=" relative z-10 mx-auto max-w-[500px] "
              />
            )}
            <button onClick={() => setOpen(false)} className=" btnn2 mt-2">
              اغلاق
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
