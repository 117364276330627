import React from "react";
import MyPartnership from "../dashboard/pages/MyJoins/partnership/MyPartnership";

export default function JoinsPartners() {
  return (
    <div className=" py-12 px-6 md:p-12 ">
      <h1 className=" text-[36px] font-[800] text-center text-[#432315] ">
        مشاراكاتي في التحالفات
      </h1>
      <div className=" my-6 ">
        <MyPartnership />
      </div>
    </div>
  );
}
