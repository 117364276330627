import React from "react";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import ExportSubs from "./ExportSubs";

export default function Attends({ back, id }) {
  const { data, error } = useFetchWithToken(
    `global/Report/tourism/forOwner/showReport/${id}`
  );
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <section>
        <button onClick={() => back()} className=" text-[18px] ">
          <i className="fa-solid fa-angle-right ml-2 "></i>الرجوع
        </button>
      </section>
      {data && (
        <section>
          <h1 className=" text-xl font-bold text-center my-3 ">
            {data.tourism_report.tour_title}
          </h1>
          <table className=" my-3 ">
            <thead>
              <tr>
                <th>اجمالي الارباح بدون نسبة المنصة</th>
                <th> نسبة المنصة</th>
                <th>اجمالي المتبقي</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className=" text-center ">
                  {data.tourism_report.total_revenue} ر.س{" "}
                </td>
                <td className=" text-center ">
                  {data.tourism_report.platformFee} ر.س{" "}
                </td>
                <td className=" text-center ">
                  {data.tourism_report.net_profit} ر.س{" "}
                </td>
              </tr>
            </tbody>
          </table>
          {data.tourism_report.tour_members.length > 0 && (
            <>
              <h1 className=" text-xl font-bold text-center my-6 ">
                المشتركين
              </h1>
              <ExportSubs
                data={data.tourism_report.tour_members}
                name={data.tourism_report.tour_title}
              />
              <table>
                <thead>
                  <tr>
                    <th>الاسم</th>
                    <th>البريد</th>
                    <th>عدد الكبار</th>
                    <th>عدد الصغار</th>
                    <th>المدفوع</th>
                  </tr>
                </thead>
                <tbody>
                  {data.tourism_report.tour_members.map((user) => (
                    <tr key={user.user_id}>
                      <td className=" text-center ">{user.name}</td>
                      <td className=" text-center ">{user.email}</td>
                      <td className=" text-center ">{user.total_adults}</td>
                      <td className=" text-center ">{user.total_children}</td>
                      <td className=" text-center ">{user.total_cost} ر.س </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </section>
      )}
    </div>
  );
}
