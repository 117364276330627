import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { load } from "../../../utils/isLoadingStore";
import Loader from "../../../components/Loader/Loader";
import usePostFormData from "../../../utils/usePostForm";
import ImageCropper from "../../../components/pages/cropper/ImageCropper";
import { Link } from "react-router-dom";
const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(file);
  });
};
export default function AddCamelCard({ close, set }) {
  const [file, setFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setFile(imageDataUrl); // Open cropper with selected image
    }
  };
  const onCropComplete = (croppedBlob) => {
    // setCroppedImage(croppedImageUrl);
    // setFile(null); // Close cropper after cropping
    // Convert the Blob to a File
    const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
      type: "image/jpeg",
    });
    setCroppedImage(croppedFile);
    setFile(null); // Close cropper after cropping
  };
  const { postFormData, error, response } = usePostFormData(
    "camelOwner/camelCards/create"
  );

  const initialValues = {
    name: "",
    type: "",
    color: "",
    age: "",
    gender: "",
    strain: "",
    croppedImage: null, // Include file in initialValues
  };

  const [errorMsg, setErrorMsg] = useState({});
  const vaildte = () => {
    let errs = {};
    if (!croppedImage) {
      errs.err = "صورة الإبل مطلوبة";
    }
    setErrorMsg(errs);

    return Object.keys(errs).length === 0;
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("الاسم مطلوب"),
    type: Yup.string().required("النوع مطلوب"),
    color: Yup.string().required("اللون مطلوب"),
    age: Yup.number()
      .min(1, "العمر يجب أن يكون أكبر من 0")
      .required("العمر مطلوب"),
    gender: Yup.string().required("الجنس مطلوب"),
    strain: Yup.string().required("السلالة مطلوبة"),
    // croppedImage: Yup.mixed().required("صورة الإبل مطلوبة"), // Ensure file validation
  });

  const handleSubmit = async (values) => {
    // Handle form submission
    // console.log(values);
    // console.log(file);
    if (vaildte()) {
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("color", values.color);
      formdata.append("type", values.type);
      formdata.append("gender", values.gender);
      formdata.append("age", values.age);
      formdata.append("season", values.strain);
      formdata.append("image", croppedImage);

      await postFormData(formdata);
    }
  };

  useEffect(() => {
    if (!!response) {
      set((pre) => {
        return [...pre, response.camel_card];
      });
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const { isLoading } = useContext(load);

  return ReactDOM.createPortal(
    <div className="almarai-regular rtl p-[12px] md:px-[100px] flex items-center justify-center z-[10000] fadein fixed w-full h-screen bg-[#00000080] top-0 left-0">
      {isLoading && <Loader />}
      <section className="max-h-[95vh] overflow-auto relative p-4 md:py-[16px] md:px-[80px] bg-[#E8DCC0] w-full rounded-[16px]">
        <section className="my-2 flex items-center justify-between">
          <h1 className="text-[#432315] text-[24px] font-[600]">
            اضافة كرت الإبل
          </h1>
          <button
            onClick={() => close(false)}
            className="text-red-600 font-[600]"
          >
            X
          </button>
        </section>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ setFieldValue, errors, touched }) => (
            <Form className="grid grid-cols-1 lg:grid-cols-2 items-center gap-8 md:gap-x-16 lg:gap-x-32 flex-wrap">
              <section className="col-span-1 lg:col-span-2">
                <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                  الاسم
                </h1>
                <Field
                  name="name"
                  className="inpt"
                  type="text"
                  placeholder="الاسم"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-600"
                />
              </section>

              <section className="col-span-1">
                <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                  النوع
                </h1>
                <Field
                  name="type"
                  className="inpt"
                  type="text"
                  placeholder="النوع"
                />
                <ErrorMessage
                  name="type"
                  component="div"
                  className="text-red-600"
                />
              </section>

              <section className="col-span-1">
                <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                  اللون
                </h1>
                <Field
                  name="color"
                  className="inpt"
                  type="text"
                  placeholder="اللون"
                />
                <ErrorMessage
                  name="color"
                  component="div"
                  className="text-red-600"
                />
              </section>

              <section className="col-span-1">
                <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                  العمر بالسنوات
                </h1>
                <Field
                  name="age"
                  className="inpt"
                  type="number"
                  placeholder="العمر بالسنوات"
                />
                <ErrorMessage
                  name="age"
                  component="div"
                  className="text-red-600"
                />
              </section>

              <section className="col-span-1">
                <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                  الجنس
                </h1>
                <Field as="select" name="gender" className="inpt">
                  <option hidden value="" label="اختر الجنس" />
                  <option value="ذكر" label="ذكر" />
                  <option value="انثى" label="أنثى" />
                </Field>
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="text-red-600"
                />
              </section>

              <section className="col-span-1">
                <h1 className="my-1 text-[#432315] text-[20px] font-[800]">
                  السلالة
                </h1>
                <Field
                  name="strain"
                  className="inpt"
                  type="text"
                  placeholder="السلالة"
                />
                <ErrorMessage
                  name="strain"
                  component="div"
                  className="text-red-600"
                />
              </section>

              <section className="col-span-1 lg:col-span-2">
                <h4 className="text-[#290900] text-[20px] font-[500] my-5">
                  صورة للإبل
                </h4>
                {croppedImage && (
                  <div className="relative">
                    <button
                      onClick={() => setFile(null)}
                      className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                    >
                      {/* <i className="fa-solid fa-trash"></i> */}
                    </button>
                    <img
                      alt="new cover"
                      className="max-h-[230px] rounded-[20px] max-w-full mx-auto"
                      // src={URL.createObjectURL(file)}
                      src={URL.createObjectURL(croppedImage)}
                    />
                  </div>
                )}

                {!croppedImage && !file && (
                  <div className="font-bold text-2xl p-8 flex items-center justify-center mx-auto bg-[#F5EFDC] rounded-[16px]">
                    <section>
                      <label
                        className="cursor-pointer h-full w-full"
                        htmlFor="file"
                      >
                        <section className="text-center">
                          <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#A59382] text-center"></i>
                          <h1 className="text-[#A59382]">رفع الصورة</h1>
                        </section>
                      </label>
                      <input
                        type="file"
                        id="file"
                        // onChange={(e) => {
                        //   setFile(e.target.files[0]);
                        // }}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </section>
                  </div>
                )}

                {file && (
                  <ImageCropper
                    imageSrc={file}
                    onCropComplete={onCropComplete}
                    w={330}
                    h={182}
                  />
                )}

                {<div className="text-red-600">{errorMsg.err}</div>}
              </section>

              <section className="col-span-1 lg:col-span-2 flex items-center justify-end gap-2 flex-wrap">
                <button type="submit" className="btn2">
                  حفظ
                </button>
              </section>
              {error && (
                <section className=" lg:col-span-2 flex items-center my-3 w-full p-3 text-lg text-white bg-red-500 rounded-md ">
                  <span className=" mx-3 bg-white h-[30px] w-[30px] text-red-500 rounded-full flex items-center justify-center ">
                    <i className="fa-solid fa-exclamation  "></i>
                  </span>
                  {!!error.response.data.error ? (
                    <>
                      {error.response.data.error === "ليس لديك اشتراك نشط." ? (
                        <section className=" flex items-center gap-2 flex-wrap ">
                          {error.response.data.error}
                          <Link
                            className=" underline text-[15px] p-2  font-bold "
                            to="/packages"
                          >
                            {" "}
                            عرض الباقات
                          </Link>
                        </section>
                      ) : (
                        error.response.data.error
                      )}
                    </>
                  ) : (
                    "  هناك مشكلة"
                  )}
                </section>
              )}
            </Form>
          )}
        </Formik>
      </section>
    </div>,
    document.body
  );
}
