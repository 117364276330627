import React from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import ExportReports from "./ExportReports";

export default function Reports() {
  const { data, error } = useFetchWithReload(
    `global/Report/race/superAdmin/gard-report-race/field_owner`
  );
  // console.log(error)
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <h1 className=" my-4 text-center text-xl font-bold ">تقارير السباقات</h1>
      {data && (
        <>
          {data.report.length > 0 ? (
            <>
              <ExportReports data={data.report} />
              <table>
                <thead>
                  <tr>
                    <th>اسم السباق</th>
                    <th>منظم السباق</th>
                    <th>عدد المشاركين</th>
                    <th>اجمالي الارباح</th>
                    <th>نسبة المنصة</th>
                    <th>تاريخ البداية</th>
                    <th>تاريخ النهاية</th>
                  </tr>
                </thead>
                <tbody>
                  {data.report.map((race, inx) => (
                    <tr key={inx}>
                      <td className=" text-center ">{race.race_name}</td>
                      <td className=" text-center ">{race.organizer}</td>
                      <td className=" text-center ">
                        {race.total_participants}
                      </td>
                      <td className=" text-center ">
                        {race.total_revenue} ر.س{" "}
                      </td>
                      <td className=" text-center ">
                        {race.platform_percentage}%
                      </td>
                      <td className=" text-center ">{race.start_date}</td>
                      <td className=" text-center ">{race.end_date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <h1 className=" text-red-600 text-xl font-bold text-center ">
                لا توجد تقارير
              </h1>
            </>
          )}
        </>
      )}
    </div>
  );
}
