import React from "react";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import ExportSubs from "./ExportSubs";

export default function Subs({ back, id }) {
  const { data, error } = useFetchWithToken(
    `global/Report/Investment/forOwner/showReport/${id}`
  );
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  const roles = {
    camel_owner: "مالك ابل",
    field_owner: "صاحب ساحة",
    service_provider: "مقدم خدمة",
    visitor: "زائر",
    super_admin: "ادارة",
  };
  return (
    <div>
      <section>
        <button onClick={() => back()} className=" text-[18px] ">
          <i className="fa-solid fa-angle-right ml-2 "></i>الرجوع
        </button>
      </section>
      <section>
        {data && (
          <section className=" my-6 ">
            <h1>
              اسم الاستثمار :{" "}
              <span className=" text-lg font-bold ">
                {data.investment_title}
              </span>
            </h1>
            <h1>
              اجمالي الاستثمار :{" "}
              <span className=" text-lg font-bold ">
                {data.total_investment} ر.س
              </span>
            </h1>
            <h1>
              سعر السهم :{" "}
              <span className=" text-lg font-bold ">
                {data.price_per_share} ر.س
              </span>
            </h1>
            <h1>
              الاسهم المتاحة :{" "}
              <span className=" text-lg font-bold ">
                {data.available_shares}
              </span>
            </h1>
            <h1>
              الاسهم المباعة :{" "}
              <span className=" text-lg font-bold ">{data.shares_sold}</span>
            </h1>
            <table className=" my-3 ">
              <thead>
                <tr>
                  <th>اجمالي الارباح بدون نسبة المنصة</th>
                  <th>اجمالي ما تم استلامه</th>
                  <th>اجمالي المتبقي</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className=" text-center ">
                    {data.revenue_before_platformFee} ر.س{" "}
                  </td>
                  <td className=" text-center ">{data.total_paid} ر.س </td>
                  <td className=" text-center ">
                    {data.remaining_amount} ر.س{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        )}
        {data && (
          <>
            <h1 className=" text-center my-2 text-lg font-bold ">المشتركين</h1>
            <ExportSubs data={data.investors} name={data.investment_title} />
            <table>
              <thead>
                <tr className=" grid grid-cols-4 ">
                  <th>الاسم</th>
                  <th>نوع المستخدم</th>
                  <th>الايميل</th>
                  <th>عدد الاسهم</th>
                </tr>
              </thead>
              <tbody>
                {data.investors.map((e, inx) => (
                  <tr className=" grid grid-cols-4 text-center " key={inx}>
                    <td>{e.name}</td>
                    <td>{roles[e.role]}</td>
                    <td>{e.email}</td>
                    <td>{e.total_shares}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </section>
    </div>
  );
}
