import React, { useEffect, useState } from "react";
import useFetchWithReload from "../../../../../utils/useGetWithReload";
import ExportTourism from "./ExportTourism";
import ExportSubs from "./ExpportSubs";

export default function Reports() {
  const [reload, setReload] = useState(false);
  const [type, setType] = useState("camel_owner");
  const handelChange = (e) => {
    setType(e.target.value);
    setReload(!reload);
  };

  const { data, error } = useFetchWithReload(
    `superAdmin/Report/tourism/allReport/${type}`,
    reload
  );

  const [show, setShow] = useState([]);
  const [reports, setResports] = useState({});
  useEffect(() => {
    if (!!data) {
      if (data.tourism_report) {
        setShow(Object.keys(data.tourism_report));
        setResports(data.tourism_report);
      }
    }
  }, [data]);

  // if (error) {
  //   return (
  // <div>
  //   <h1 className=" text-red-600 text-xl font-bold text-center ">
  //     لا توجد رحلات
  //   </h1>
  // </div>
  //   );
  // }
  const names = {
    camel_owner: "مالك ابل",
    field_owner: "مالك ساحة",
    service_provider: "مقدم خدمة",
    visitor: "زائر",
    super_admin: "ادمن",
  };
  return (
    <div>
      <div className="flex items-center justify-end flex-wrap gap-y-3 md:gap-2">
        <select
          className=" bg-white/0 w-full max-w-[250px] outline-none p-2  border rounded-[11px] border-black/60 "
          value={type}
          onChange={handelChange}
        >
          <option value={"camel_owner"}>مالك ابل</option>
          <option value={"service_provider"}>مزود خدمة</option>
          <option value={"field_owner"}>مالك ساحة</option>
        </select>
      </div>
      {error ? (
        <div>
          <h1 className=" text-red-600 text-xl font-bold text-center ">
            لا توجد رحلات
          </h1>
        </div>
      ) : (
        <>
          {reports && (
            <div>
              <h1 className=" text-xl font-bold text-center my-6 ">
                تقارير السياحة
              </h1>
              {/* <table>
                <thead>
                  <tr>
                    <th>اسم الرحلة</th>
                    <th>ارباح الرحلة</th>
                    <th>نسبة المنصة</th>
                    <th>صافي الارباح</th>
                    <th>عدد المشتركين</th>
                  </tr>
                </thead>
                <tbody>
                  {show.map((item) => (
                    <tr key={item}>
                      <td className="  text-center ">
                        {reports[item].tour_title}
                      </td>
                      <td className="  text-center ">
                        {reports[item].total_revenue} ر.س
                      </td>
                      <td className="  text-center ">
                        {reports[item].platformFee} ر.س
                      </td>
                      <td className="  text-center ">
                        {reports[item].net_profit} ر.س
                      </td>
                      <td className="  text-center ">
                        {reports[item].tour_members.length}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> */}

              <div className="">
                <ExportTourism data={show} reports={reports} type={type} />
                {show.map((tour, inx) => (
                  <section
                    key={inx}
                    className=" border rounded-[10px] p-[15px] border-black/40 my-4 "
                  >
                    <table>
                      <thead>
                        <tr>
                          <th>اسم الرحلة</th>
                          <th>ارباح الرحلة</th>
                          <th>نسبة المنصة</th>
                          <th>صافي الارباح</th>
                          <th>عدد المشتركين</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="  text-center ">
                            {reports[tour].tour_title}
                          </td>
                          <td className="  text-center ">
                            {reports[tour].total_revenue} ر.س
                          </td>
                          <td className="  text-center ">
                            {reports[tour].platformFee} ر.س
                          </td>
                          <td className="  text-center ">
                            {reports[tour].net_profit} ر.س
                          </td>
                          <td className="  text-center ">
                            {reports[tour].tour_members.length}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {reports[tour].tour_members.length === 0 ? (
                      <p className=" text-center my-2 text-red-600 text-[18px]  ">
                        لا يوجد مشتركين
                      </p>
                    ) : (
                      <>
                        <h1 className=" text-center text-[20px] font-bold my-2 ">
                          المشتركين
                        </h1>
                        <ExportSubs
                          name={reports[tour].tour_title}
                          data={reports[tour].tour_members}
                        />
                        <table>
                          <thead>
                            <tr>
                              <th>الاسم</th>
                              <th>نوع المستخدم</th>
                              <th>البريد الاكتروني</th>
                              <th>اجمالي التكلفة</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reports[tour].tour_members.map((user, i) => (
                              <tr key={i}>
                                <td className="text-center">{user.name}</td>
                                <td className="text-center">
                                  {names[user.role]}
                                </td>
                                <td className="text-center">{user.email}</td>
                                <td className="text-center">
                                  {user.total_cost} ر.س{" "}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    )}
                  </section>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
