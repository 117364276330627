import React from "react";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import Request from "./Request";
import RequestsExport from "./RequestsExport";

export default function Requests() {
  const { data, error } = useFetchWithToken(
    "global/store/requested-items/my-all-requested-items"
  );

  return (
    <div className="  w-full ">
      <h1 className=" text-center my-4 font-bold text-2xl  ">الطلبات</h1>
      {data && <RequestsExport data={data.items} />}
      {error && (
        <h1 className=" text-center my-4 font-bold text-lg text-red-700  ">
          لا توجد اي طلبات
        </h1>
      )}
      {data && (
        <>
          {data.items ? (
            <>
              {data.items.map((item) => (
                <Request key={item.id} data={item} />
              ))}
            </>
          ) : (
            <h1 className=" text-center my-4 font-bold text-lg text-red-700  ">
              لا توجد اي طلبات
            </h1>
          )}
        </>
      )}
    </div>
  );
}
