import React, { useEffect, useState } from "react";
import Hr from "../../components/pages/Hr";
import Owner from "../../components/pages/Home/Owner";
import useFetchGlobal from "../../utils/useGetGlobal";

export default function ALlOwners() {
  const { data } = useFetchGlobal("global/CamelOwners/all");
  const [allOwners, setAllOwners] = useState([]);
  const [search, setSearch] = useState({
    name: "",
    location: "",
    color: "",
  });
  const handelChange = (e) => {
    let { name, value } = e.target;
    setSearch({ ...search, [name]: value });
  };
  useEffect(() => {
    let filtered = [];
    if (data) {
      filtered = data["camel_owners"];
      if (!!search.name) {
        filtered = filtered.filter((i) => {
          let name = i.first_name + " " + i.last_name;
          return name
            .toLocaleLowerCase()
            .trim()
            .includes(search.name.toLocaleLowerCase().trim());
        });
      }
      if (!!search.location) {
        filtered = filtered.filter((i) =>
          i.address
            .toLocaleLowerCase()
            .trim()
            .includes(search.location.toLocaleLowerCase().trim())
        );
      }
      if (!!search.color) {
        filtered = filtered.filter((i) => i.camel_color === search.color);
      }
    }
    setAllOwners(filtered);
  }, [data, search]);
  return (
    <div>
      <div className="  py-12 px-8 md:px-16 relative   ">
        <div className=" absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full">
          <img
            src="./imgs/home/owners.jfif"
            alt="Vision"
            className="absolute left-0 top-0 h-full w-full object-cover brightness-50"
          />
        </div>
        <div className=" bg-[#432315A3]/50 absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full"></div>

        <div className=" w-full backdrop-blur-[3px]  max-w-[540px] mx-auto py-[30px] px-[15px] bg-[#6B65618F]/50 text-center rounded-[15px] relative z-10">
          <h1 className=" my-2 text-[#E8DCC0] text-[32px] md:text-[48px] font-[700] ">
            ملاك الابل
          </h1>
          <Hr color="#E8DCC0" />
          <p className=" my-2 text-[#E8DCC0] leading-[40px] text-[18px] font-[600] ">
            هم الاشخاص الذين يملكون الابل ويهتمون بها . هذا الشغف العريق الذي
            يمتد عبر التاريخ حيث كانت الابل عنصرًا أساسيًا في حياة البدو
            والتجارة والتنقل في شبه الجزيرة العربية والعديد من المناطق الاخري .
          </p>
        </div>
      </div>

      {/* Search */}
      <section className=" p-4 mt-6 flex items-center justify-center gap-4 flex-wrap ">
        <div className="flex items-center text-[#243F32] w-full max-w-[360px] rounded-[10px] border border-[#432315]">
          <i className="fa-solid fa-magnifying-glass m-2"></i>
          <input
            type="text"
            value={search.name}
            name="name"
            onChange={handelChange}
            placeholder="أبحث هنا..."
            className="outline-none bg-[black]/0 flex-1 p-2"
          />
        </div>
        <div className="flex items-center text-[#243F32] w-full max-w-[360px] rounded-[10px] border border-[#432315]">
          <i className="fa-solid fa-magnifying-glass m-2"></i>
          <input
            type="text"
            value={search.location}
            name="location"
            onChange={handelChange}
            placeholder="العنوان..."
            className="outline-none bg-[black]/0 flex-1 p-2"
          />
        </div>
        <div className="flex items-center text-[#243F32] w-full max-w-[360px] rounded-[10px] border border-[#432315]">
          <i className="fa-solid fa-magnifying-glass m-2"></i>
          <select
            value={search.color}
            name="color"
            onChange={handelChange}
            placeholder="أبحث هنا..."
            className="outline-none bg-[black]/0 flex-1 p-2"
          >
            <option hidden>انواع الابل المختص بها</option>
            <option value="السوداء">السوداء</option>
            <option value="البيض">البيض</option>
            <option value="الحمراء">الحمراء</option>
            <option value="البيضاء النقية">البيضاء النقية</option>
            <option value="الزُرْق">الزُرْق </option>
            <option value="الصفراء">الصفراء </option>
            <option value="الداكنة البنية">الداكنة البنية </option>
            <option value="الملحاء">الملحاء </option>
          </select>
        </div>
      </section>

      {/* Owners */}
      {!!data && (
        <div className=" flex items-start justify-center gap-12 flex-wrap p-6 md:px-14">
          {allOwners.map((e) => (
            <Owner key={e.id} owner={e} />
          ))}
          {/* {data["camel_owners"].map((e) => (
            <Owner key={e.id} owner={e} />
          ))} */}
        </div>
      )}
    </div>
  );
}
