import React from "react";
import useFetchWithToken from "../../../../utils/useGetWithToken";
import ExportsReports from "./ExportsReports";

export default function Reports({ back }) {
  let link = "global/Report/event/owner/detailed-report";
  const { data, error } = useFetchWithToken(link);
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div>
      <section>
        <button onClick={back} className=" text-[18px] ">
          <i className="fa-solid fa-angle-right ml-2 "></i>الرجوع
        </button>
      </section>
      {data && (
        <div>
          {data.report && (
            <div>
              {data.report.events && (
                <>
                  <ExportsReports data={data.report.events} />
                  <table>
                    <thead>
                      <tr>
                        <th>اسم الفعالية</th>
                        <th>عدد المشاركين</th>
                        <th>اجمالي الارباح</th>
                        <th>نسبة المنصة</th>
                        <th>تاريخ البداية</th>
                        <th>تاريخ النهاية</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.report.events.map((event, inx) => (
                        <tr key={inx}>
                          <td className=" text-center ">{event.event_name}</td>
                          <td className=" text-center ">
                            {event.total_participants}
                          </td>
                          <td className=" text-center ">
                            {event.total_revenue} ر.س
                          </td>
                          <td className=" text-center ">
                            {event.platform_percentage}%
                          </td>
                          <td className=" text-center ">{event.start_date}</td>
                          <td className=" text-center ">{event.end_date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {data.report.events.length === 0 && (
                    <h1 className=" text-center my-4 text-xl ">
                      لا توجد فعاليات
                    </h1>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
