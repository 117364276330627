import React, { useEffect, useState } from "react";

import useFetchWithReload from "../../../../utils/useGetWithReload";
import usePostWithToken from "../../../../utils/usePostJson";

export default function CamelOwners() {
  const [reload, setReload] = useState(false);
  const re = () => {
    setReload(!reload);
  };
  const { data } = useFetchWithReload("global/CamelOwners/all", reload);
  const [soretedData, setSortedData] = useState([]);
  useEffect(() => {
    if (data) {
      let da = [];
      data["camel_owners"].forEach((element) => {
        da.push({
          id: element.id,
          sort_order: element.sort_order,
        });
      });
      setSortedData(da);
    }
  }, [data]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    let da = [];
    if (data) {
      da = data["camel_owners"];
    }
    if (!!search) {
      da = da.filter((i) => {
        let name = i.first_name + " " + i.last_name;
        return name.toLowerCase().includes(search.toLowerCase());
      });
    }
    setFilteredData(da);
  }, [data, search]);
  const handelChange = (e, inx) => {
    let newDa = soretedData.map((i, index) =>
      index === inx ? { ...i, sort_order: Number(e.target.value) } : i
    );
    setSortedData(newDa);
  };

  const { postData, response } = usePostWithToken(`superAdmin/sort/camelOwner`);
  const handelUpdate = () => {
    postData({ sort_orders: soretedData });
  };

  useEffect(() => {
    if (response) {
      re();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <div className=" p-8 ">
      <section className="w-full max-w-[450px] flex items-center gap-6 justify-between">
        <div className="flex items-center text-[#243F32] flex-1 rounded-[10px] border border-[#432315]">
          <i className="fa-solid fa-magnifying-glass m-2"></i>
          <input
            type="text"
            value={search}
            name="search"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="أبحث هنا..."
            className="outline-none bg-[black]/0 flex-1 p-2"
          />
        </div>
      </section>
      <div className=" flex justify-end my-3 ">
        <button onClick={handelUpdate} className="btn2">تحديث</button>
      </div>
      <section className=" my-4 ">
        <table>
          <thead>
            <tr>
              <th>الترتيب</th>
              <th>الاسم</th>
              <th>الصورة</th>
              <th>الجنسية</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((i, inx) => (
              <tr key={i.id}>
                <td className=" text-center  w-[150px] px-5  ">
                  <input
                    // value={i.sort_order}
                    onChange={(e) => handelChange(e, inx)}
                    value={soretedData[inx].sort_order}
                    type="number"
                    className="inpt w-[80px] text-center "
                  />
                </td>
                <td className=" text-center ">
                  {i.first_name} {i.last_name}
                </td>
                <td className=" text-center ">
                  {i.image ? (
                    <img
                      className=" mx-auto h-[50px] rounded-[8px]"
                      src={
                        i.image
                          ? `${process.env.REACT_APP_URL}/${i.image}`
                          : "./imgs/noImage.jpg"
                      }
                      alt={i.first_name}
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td className=" text-center ">{i.nationality}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}
