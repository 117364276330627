import React from "react";
import MyTrips from "../dashboard/pages/MyJoins/trips/MyTrips";

export default function JoinsTrips() {
  return (
    <div className=" py-12 px-6 md:p-12 ">
      <h1 className=" text-[36px] font-[800] text-center text-[#432315] ">
        مشاراكاتي في الرحلات
      </h1>
      <div className=" my-6 ">
        <MyTrips />
      </div>
    </div>
  );
}
