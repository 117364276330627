import React, { useEffect, useState } from "react";
import LoginLayout from "../Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SelectBtn2 from "../../../components/pages/SelectBtn2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import usePostWithToken from "../../../utils/usePostJson";
import usePostFormData from "../../../utils/usePostForm";
import FileUploded from "../../../components/pages/FileUploded";
import ImageCropper from "../../../components/pages/cropper/ImageCropper";

export default function Register() {
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type");
  const [values, setValues] = useState({
    type: type ? type : "visitor",
    serverType: "veterinary",
    //
    first_name: "",
    last_name: "",
    address: "",
    phone: "",
    email: "",
    nationality: "",
    gender: "",
    password: "",
    password_confirmation: "",
    documents: [],
    checked: false,
    camel_brand_name: "",
    camel_color: "",
    camel_brand_logo: "",
    // role: "visitor",
  });
  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result));
      reader.readAsDataURL(file);
    });
  };
  const [croppedImage, setCroppedImage] = useState(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageDataUrl = await readFile(file);
      setValues({ ...values, camel_brand_logo: imageDataUrl });
    }
  };
  const onCropComplete = (croppedBlob) => {
    // setCroppedImage(croppedImageUrl);
    // setFile(null); // Close cropper after cropping
    // Convert the Blob to a File
    const croppedFile = new File([croppedBlob], "cropped-image.jpg", {
      type: "image/jpeg",
    });
    setCroppedImage(croppedFile);
    setValues({ ...values, camel_brand_logo: null }); // Close cropper after cropping
  };
  const [errors, setErrors] = useState({});
  const validate = () => {
    let formErrors = {};

    // Validate first name
    if (!values.first_name) {
      formErrors.first_name = "الرجاء إدخال الاسم الأول";
    }

    // Validate last name
    if (!values.last_name) {
      formErrors.last_name = "الرجاء إدخال اسم العائلة";
    }

    // Validate address
    if (!values.address) {
      formErrors.address = "الرجاء إدخال العنوان";
    }

    // Validate phone
    if (!values.phone) {
      formErrors.phone = "الرجاء إدخال رقم الهاتف";
    }

    // Validate email
    if (!values.email) {
      formErrors.email = "الرجاء إدخال البريد الإلكتروني";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      formErrors.email = "الرجاء إدخال بريد إلكتروني صحيح";
    }

    // Validate nationality
    if (!values.nationality) {
      formErrors.nationality = "الرجاء إدخال الجنسية";
    }

    // Validate gender
    if (!values.gender) {
      formErrors.gender = "الرجاء تحديد الجنس";
    }
    if (!values.checked) {
      formErrors.checked = "يجب الموافقة علي الشروط";
    }
    if (!values.camel_brand_name && values.type === "camel_owner") {
      formErrors.camel_brand_name = "يجب  ادخال اسم الوسم";
    }
    if (!croppedImage && values.type === "camel_owner") {
      formErrors.camel_brand_logo = "يجب  ادخال صورة الوسم";
    }
    if (!values.camel_color && values.type === "camel_owner") {
      formErrors.camel_color = "يجب  اختيار لون الابل المختص بها";
    }

    // Validate password
    if (!values.password) {
      formErrors.password = "الرجاء إدخال كلمة المرور";
    } else if (values.password.length < 8) {
      formErrors.password = "كلمة المرور يجب أن تكون 8 أحرف على الأقل";
    }

    // Validate password confirmation
    if (!values.password_confirmation) {
      formErrors.password_confirmation = "الرجاء تأكيد كلمة المرور";
    } else if (values.password !== values.password_confirmation) {
      formErrors.password_confirmation = "كلمة المرور غير متطابقة";
    }

    setErrors(formErrors);

    // If no errors, form is valid
    return Object.keys(formErrors).length === 0;
  };
  let link = "";
  if (values.type === "visitor") {
    link = "global/visitor/register";
  }
  if (values.type === "camel_owner") {
    link = "global/CamelOwners/register";
  }
  if (values.type === "field_owner") {
    link = "global/fieldOwner/register";
  }
  if (values.type === "service_provider") {
    link = "global/serviceProvider/register";
  }
  // const { postData, response, error } = usePostWithToken(link);
  // const { postFormData, response, error } = usePostFormData(link);
  const {
    postData,
    response: tokenResponse,
    error: tokenError,
  } = usePostWithToken(link);
  const {
    postFormData,
    response: formDataResponse,
    error: formDataError,
  } = usePostFormData(link);
  const navgate = useNavigate();

  const handelRegister = async () => {
    if (validate()) {
      if (values.type === "service_provider") {
        const formdata = new FormData();
        formdata.append("first_name", values.first_name);
        formdata.append("last_name", values.last_name);
        formdata.append("address", values.address);
        formdata.append("phone", values.phone);
        formdata.append("email", values.email);
        formdata.append("nationality", values.nationality);
        formdata.append("gender", values.gender);
        formdata.append("password", values.password);
        formdata.append("password_confirmation", values.password_confirmation);
        formdata.append("role", values.type);
        formdata.append("type", values.serverType);
        values.documents.forEach((image) => {
          formdata.append("documents[]", image);
        });

        await postFormData(formdata);
      } else {
        if (values.type === "camel_owner") {
          const formdata = new FormData();
          formdata.append("first_name", values.first_name);
          formdata.append("last_name", values.last_name);
          formdata.append("address", values.address);
          formdata.append("phone", values.phone);
          formdata.append("email", values.email);
          formdata.append("nationality", values.nationality);
          formdata.append("gender", values.gender);
          formdata.append("password", values.password);
          formdata.append(
            "password_confirmation",
            values.password_confirmation
          );
          formdata.append("role", values.type);
          formdata.append("camel_brand_name", values.camel_brand_name);
          formdata.append("camel_brand_logo", croppedImage);
          formdata.append("camel_color", values.camel_color);

          await postFormData(formdata);
        } else {
          await postData({
            first_name: values.first_name,
            last_name: values.last_name,
            address: values.address,
            phone: values.phone,
            email: values.email,
            nationality: values.nationality,
            gender: values.gender,
            password: values.password,
            password_confirmation: values.password_confirmation,
            role: values.type,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (tokenResponse || formDataResponse) {
      navgate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenResponse, formDataResponse]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setValues({ ...values, [name]: value });
  };

  return (
    <LoginLayout>
      <div className="w-full relative md:max-h-[80vh] overflow-auto ">
        <Link
          className=" md:absolute flex gap-1 items-center top-2 right-2 "
          to="/"
        >
          <i className="fa-solid fa-arrow-right"></i>
          <button className="  ">الصفحة الرئيسية</button>
        </Link>
        <section className="px-6 md:px-12 lg:px-24 w-full">
          <h1 className="text-center text-[25px] md:text-[30px] font-[700] text-[#290900] my-2">
            انشاء حساب
          </h1>
          <section className="flex items-center gap-1 justify-between flex-wrap">
            <SelectBtn2
              // dis={isSubmitting}
              name="type"
              handelVal={handleChange}
              text="زائر"
              val="visitor"
              mainval={values.type}
            />
            <SelectBtn2
              // dis={isSubmitting}
              name="type"
              handelVal={handleChange}
              text="مالك ابل"
              val="camel_owner"
              mainval={values.type}
            />
            <SelectBtn2
              // dis={isSubmitting}
              name="type"
              handelVal={handleChange}
              text="الساحة"
              val="field_owner"
              mainval={values.type}
            />
            <SelectBtn2
              // dis={isSubmitting}
              name="type"
              handelVal={handleChange}
              text="مزود خدمة"
              val="service_provider"
              mainval={values.type}
            />
          </section>
          {values.type === "service_provider" && (
            <>
              <section className=" mt-2 flex items-center gap-1 justify-between flex-wrap">
                <SelectBtn2
                  // dis={isSubmitting}
                  name="serverType"
                  handelVal={handleChange}
                  text="طب بيطري"
                  val="veterinary"
                  mainval={values.serverType}
                />
                <SelectBtn2
                  // dis={isSubmitting}
                  name="serverType"
                  handelVal={handleChange}
                  text="مستقل"
                  val="freelancer"
                  mainval={values.serverType}
                />
                <SelectBtn2
                  // dis={isSubmitting}
                  name="serverType"
                  handelVal={handleChange}
                  text="خبير"
                  val="expert"
                  mainval={values.serverType}
                />
              </section>
              <section className=" col-span-1 lg:col-span-2 ">
                <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                  الملفات التعريفية
                </h1>
                <section className=" flex items-center flex-wrap gap-2 ">
                  {values.documents.map((e, inx) => {
                    // console.log(e.name);
                    return (
                      <>
                        <FileUploded
                          key={inx}
                          da={e}
                          del={() => {
                            let da = values.documents;
                            da = da.filter((i, index) => inx !== index);
                            setValues({
                              ...values,
                              documents: da,
                            });
                          }}
                        />
                      </>
                    );
                  })}

                  <label className="btn" htmlFor="imgs">
                    <section className=" text-center ">
                      <i className="fa-solid fa-cloud-arrow-up mx-auto text-[#f2f2f2] text-center"></i>
                      <h1 className="text-[#f2f2f2]">رفع ملف</h1>
                    </section>
                  </label>
                  <input
                    type="file"
                    id="imgs"
                    accept="image/*"
                    onChange={(e) => {
                      if (!!e.target.files[0]) {
                        setValues({
                          ...values,
                          documents: [...values.documents, e.target.files[0]],
                        });
                      }
                    }}
                    style={{ display: "none" }}
                  />
                </section>
              </section>
            </>
          )}
          <div className="my-5 w-full">
            <section className="my-2">
              <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                الاسم الاول
              </h1>
              <input
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
                className={` w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                  errors.first_name ? "border-red-500" : ""
                }`}
                type="text"
                placeholder=" الاسم الاول"
              />
              {<div className="text-red-500 text-sm">{errors.first_name}</div>}
            </section>
            <section className="my-2">
              <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                الاسم الاخير
              </h1>
              <input
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                className={` w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                  errors.last_name ? "border-red-500" : ""
                }`}
                type="text"
                placeholder=" الاسم الاخير"
              />
              {<div className="text-red-500 text-sm">{errors.last_name}</div>}
            </section>
            <section className="my-2">
              <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                البريد الاكتروني
              </h1>
              <input
                name="email"
                value={values.email}
                onChange={handleChange}
                className={` w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                  errors.email ? "border-red-500" : ""
                }`}
                type="email"
                required
                placeholder="   البريد الاكتروني"
              />
              {<div className="text-red-500 text-sm">{errors.email}</div>}
            </section>
            <section className="my-2">
              <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                رقم الهاتف
              </h1>
              <PhoneInput
                country={"sa"}
                value={values.phone}
                onChange={(value) => {
                  setValues({ ...values, phone: value });
                }}
                className={` ltr w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                  errors.phone ? "border-red-500" : ""
                }`}
                inputStyle={{
                  outline: "none",
                  width: "100%",
                  maxWidth: "340px",
                  //   backgroundColor: "#F5EFDC",
                  padding: "12px",
                  paddingLeft: "45px",
                  borderRadius: "10px",
                  border: "none",
                  // borderBottom: "2px solid #432315",
                  color: "#432315C7",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
                dropdown={{
                  outline: "none",
                  width: "100%",
                  maxWidth: "340px",
                  backgroundColor: "#fff",
                  padding: "12px",
                  paddingLeft: "45px",
                  borderRadius: "10px",
                  border: "none",
                  // borderBottom: "2px solid #432315",
                  //   color: "#432315C7",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
                containerClass="custom-phone-input"
                inputClass="custom-phone-input-input"
                dropdownClass="custom-phone-dropdown"
              />

              {<div className="text-red-500 text-sm">{errors.phone}</div>}
            </section>
            <section className="my-2">
              <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                العنوان
              </h1>
              <input
                name="address"
                value={values.address}
                onChange={handleChange}
                className={` w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                  errors.address ? "border-red-500" : ""
                }`}
                type="text"
                placeholder="العنوان"
              />
              {<div className="text-red-500 text-sm">{errors.address}</div>}
            </section>
            <section className="my-2">
              <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                الجنسية
              </h1>
              {/* <input
                name="nationality"
                value={values.nationality}
                onChange={handleChange}
                className={` w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                  errors.nationality ? "border-red-500" : ""
                }`}
                type="text"
                placeholder="الجنسية"
              /> */}
              <select
                name="nationality"
                value={values.nationality}
                onChange={handleChange}
                className={` w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                  errors.nationality ? "border-red-500" : ""
                }`}
                type="text"
                placeholder="الجنسية"
                // required
              >
                <option hidden>الجنسية</option>
                <option value={"السعودية"}>السعودية</option>
                <option value={"الامارات"}>الامارات</option>
                <option value={"البحرين"}>البحرين</option>
                <option value={"قطر"}>قطر</option>
                <option value={"مصر"}>مصر</option>
                <option value={"اخري"}>اخري</option>
              </select>
              {<div className="text-red-500 text-sm">{errors.nationality}</div>}
            </section>
            <section className="my-2">
              <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                الجنس
              </h1>
              <select
                name="gender"
                value={values.gender}
                onChange={handleChange}
                className={` w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                  errors.gender ? "border-red-500" : ""
                }`}
              >
                <option hidden>الجنس</option>
                <option value="male">ذكر</option>
                <option value="female">انثي</option>
              </select>

              {<div className="text-red-500 text-sm">{errors.gender}</div>}
            </section>
            <section className="my-2">
              <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                كلمة السر
              </h1>
              <input
                name="password"
                value={values.password}
                onChange={handleChange}
                className={` w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                  errors.password ? "border-red-500" : ""
                }`}
                type="password"
                placeholder="كلمة السر"
              />
              {<div className="text-red-500 text-sm">{errors.password}</div>}
            </section>
            <section className="my-2">
              <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                تاكيد كلمة السر
              </h1>
              <input
                name="password_confirmation"
                value={values.password_confirmation}
                onChange={handleChange}
                className={` w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                  errors.password_confirmation ? "border-red-500" : ""
                }`}
                type="password"
                placeholder=" تاكيد  كلمة السر"
              />
              {
                <div className="text-red-500 text-sm">
                  {errors.password_confirmation}
                </div>
              }
            </section>
            {values.type === "camel_owner" && (
              <>
                <section className="my-2">
                  <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                    انواع الابل المختص بها
                  </h1>
                  <select
                    name="camel_color"
                    value={values.camel_color}
                    onChange={handleChange}
                    className={` w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                      errors.camel_color ? "border-red-500" : ""
                    }`}
                  >
                    <option hidden>انواع الابل المختص بها</option>
                    <option value="السوداء">السوداء</option>
                    <option value="البيض">البيض</option>
                    <option value="الحمراء">الحمراء</option>
                    <option value="البيضاء النقية">البيضاء النقية</option>
                    <option value="الزُرْق">الزُرْق </option>
                    <option value="الصفراء">الصفراء </option>
                    <option value="الداكنة البنية">الداكنة البنية </option>
                    <option value="الملحاء">الملحاء </option>
                  </select>
                  <div className="text-red-500 text-sm">
                    {errors.camel_color}
                  </div>
                </section>
                <section className="my-2">
                  <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                    الوسم
                  </h1>
                  <input
                    name="camel_brand_name"
                    value={values.camel_brand_name}
                    onChange={handleChange}
                    className={` w-full outline-none p-2 border transition-all ease-in-out duration-500 my-1 text-[#290900] focus:border-[#290900] border-[#AAAAAA] rounded-[8px] ${
                      errors.camel_brand_name ? "border-red-500" : ""
                    }`}
                    placeholder="الوسم"
                  />
                  {
                    <div className="text-red-500 text-sm">
                      {errors.camel_brand_name}
                    </div>
                  }
                </section>
                <section className="my-2">
                  <h1 className="text-[16px] font-[700] text-[#290900] my-1">
                    صورة الوسم
                  </h1>
                  {croppedImage && (
                    <div className="relative">
                      <button
                        onClick={() => setCroppedImage(null)}
                        className="text-red-500/70 hover:text-red-500 cursor-pointer text-lg"
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                      <img
                        alt="new cover"
                        className="max-h-[80px] rounded-full max-w-full mx-auto"
                        // src={URL.createObjectURL(file)}
                        src={URL.createObjectURL(croppedImage)}
                      />
                    </div>
                  )}
                  {!croppedImage && !values.camel_brand_logo && (
                    <section className=" flex items-center gap-2 ">
                      <label>رفع صورة :</label>
                      <input
                        type="file"
                        id="file"
                        accept="image/*"
                        // onChange={(e) => {
                        //   setForm({ ...form, field_image: e.target.files[0] });
                        // }}
                        onChange={handleFileChange}
                      />
                    </section>
                  )}
                  {values.camel_brand_logo && (
                    <ImageCropper
                      imageSrc={values.camel_brand_logo}
                      onCropComplete={onCropComplete}
                      w={600}
                      h={600}
                    />
                  )}
                  {
                    <div className="text-red-500 text-sm">
                      {errors.camel_brand_logo}
                    </div>
                  }
                </section>
              </>
            )}
            <section className="my-2 flex items-center flex-wrap  ">
              <input
                type="checkbox"
                id={`customCheckbox-1`}
                className="custom-checkbox"
                name="checked"
                checked={values.checked}
                onChange={(e) =>
                  setValues({ ...values, checked: e.target.checked })
                }
              />
              <label
                htmlFor={`customCheckbox-1`}
                className="text-[16px] text-[#432315] font-[800] mx-3 "
              >
                الموافقة علي{" "}
                <Link className=" underline " to="/policies">
                  الشروط والاحكام
                </Link>
              </label>
              {<div className="text-red-500 text-sm">{errors.checked}</div>}
            </section>

            {tokenError && (
              <>
                <section className=" my-2 p-2 text-white rounded-[8px] bg-red-500  ">
                  {tokenError.response ? (
                    <>
                      {tokenError.response.data ? (
                        <>
                          {tokenError.response.data.error
                            ? tokenError.response.data.error
                            : "هناك مشكلة في التسجيل"}
                        </>
                      ) : (
                        "هناك مشكلة في التسجيل"
                      )}
                    </>
                  ) : (
                    "هناك مشكلة في التسجيل"
                  )}
                </section>
              </>
            )}
            {formDataError && (
              <>
                <section className=" my-2 p-2 text-white rounded-[8px] bg-red-500  ">
                  {formDataError.response ? (
                    <>
                      {formDataError.response.data ? (
                        <>
                          {formDataError.response.data.error
                            ? formDataError.response.data.error
                            : "هناك مشكلة في التسجيل"}
                        </>
                      ) : (
                        "هناك مشكلة في التسجيل"
                      )}
                    </>
                  ) : (
                    "هناك مشكلة في التسجيل"
                  )}
                </section>
              </>
            )}

            <button
              type="submit"
              onClick={handelRegister}
              //   disabled={isSubmitting}
              className="w-full text-[#FFFCF7] text-[20px] font-[600] bg-[#432315] rounded-[8px] h-[50px]"
            >
              انشاء حساب
            </button>
          </div>

          <section className=" relative text-center my-3 w-full ">
            <hr className=" z-0 w-full absolute top-[12px] " />
            <Link to="/login">
              <h1 className="relative z-10 text-[#290900]/50 font-[400] text-[16px] px-2 bg-[#fff] w-fit mx-auto ">
                لديك حساب؟ تسجيل دخول
              </h1>
            </Link>
          </section>
        </section>
      </div>
    </LoginLayout>
  );
}
