import React, { useEffect, useState } from "react";
import usePostWithToken from "../../../../../../utils/usePostJson";
import { Menu } from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import font from "../../../../../../utils/Amiri-Regular-normal";
import { CSVLink } from "react-csv";

const names = {
  camel_owner: "مالك ابل",
  field_owner: "مالك ساحة",
  service_provider: "مقدم خدمة",
  visitor: "زائر",
};

const generatePDF = (data, type) => {
  const doc = new jsPDF();

  doc.addFileToVFS("Amiri-Regular.ttf", font);
  doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
  doc.setFont("Amiri");
  doc.setFontSize(12);

  const tableColumn = [
    "الارباح",
    "عدد مرات الشراء",
    "سعر الباقة",
    "اسم الباقة",
  ];
  const tableRows = [];

  data.forEach((item, index) => {
    const tableData = [
      `${item.package_revenue} ر.س `,
      item.subscription_count,
      `${item.package_price} ر.س `,
      item.package_name,
    ];
    tableRows.push(tableData);
  });

  doc.text(` تقارير الباقات - ${names[type]} `, 190, 15, { align: "right" });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 25,
    styles: {
      font: "Amiri",
      fontSize: 12,
    },
    bodyStyles: { valign: "middle", halign: "right" },
    headStyles: { fillColor: [63, 81, 181], halign: "right" },
    tableLineWidth: 0.1,
    margin: { left: 10, right: 10 },
  });
  doc.save(`تقارير الباقات - ${names[type]}.pdf`);
};

export default function Details({ type, reload }) {
  const { postData, response, error } = usePostWithToken(
    `superAdmin/Report/package/revenue_for_guard`
  );
  const getData = () => {
    const data = {
      guard_type: type,
    };
    postData(data);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // let body = [];
  const [body, setBody] = useState([]);
  useEffect(() => {
    if (response) {
      if (response.details) {
        let co = [];
        // eslint-disable-next-line array-callback-return
        response.details.map((i) => {
          co.push({
            name: i.package_name,
            price: i.package_price,
            count: i.subscription_count,
            total: i.package_revenue,
          });
        });
        setBody(co);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const headers = [
    { label: "اسم الباقة", key: "name" },
    { label: "سعر الباقة", key: "price" },
    { label: "عدد مرات الشراء", key: "count" },
    { label: "الارباح", key: "total" },
  ];
  if (error) {
    return (
      <div>
        <h1 className=" text-red-600 text-xl font-bold text-center ">
          هناك مشكلة
        </h1>
      </div>
    );
  }
  return (
    <div className=" my-8 ">
      {response && (
        <>
          <h1 className=" text-xl my-3 font-[900] text-center ">
            اجمالي الارباح : {response.total_revenue} ر.س
          </h1>
          <section className=" flex items-center justify-end ">
            {response.details && (
              <button
                onClick={handleClick}
                className=" btn2 flex items-center justify-center gap-2 "
              >
                تصدير <i class="fa-solid fa-caret-down" />
              </button>
            )}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <button
                onClick={() => {
                  handleClose();
                  generatePDF(response.details, type);
                }}
                className=" py-2 px-12 border-b flex items-center justify-center gap-2 "
              >
                PDF <i className="fa-solid fa-file-pdf"></i>
              </button>
              <button
                onClick={() => {
                  handleClose();
                }}
                className=" "
              >
                <CSVLink
                  data={body}
                  headers={headers}
                  filename={`تقارير الباقات - ${names[type]}.csv`}
                  target="_blank"
                  className=" w-full  py-2 px-12 border-b flex items-center justify-center gap-2 "
                >
                  CVS <i className="fa-solid fa-table"></i>
                </CSVLink>
              </button>
              {/* <br></br> */}
            </Menu>
          </section>
          {response.details && (
            <table>
              <thead>
                <tr>
                  <th>اسم الباقة</th>
                  <th>سعر الباقة</th>
                  <th>عدد مرات الشراء</th>
                  <th>الارباح</th>
                </tr>
              </thead>
              <tbody>
                {response.details.map((pa, inx) => (
                  <tr key={inx}>
                    <td className=" text-center ">{pa.package_name}</td>
                    <td className=" text-center ">{pa.package_price} ر.س</td>
                    <td className=" text-center ">{pa.subscription_count}</td>
                    <td className=" text-center ">{pa.package_revenue} ر.س</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
}
