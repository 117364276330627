import React, { useEffect, useState } from "react";
// import useFetchWithReload from "../../../../../utils/useGetWithReload";
import useFetchReports from "../../../../../utils/useGetReports";
import ExportReports from "./ExportReports";

export default function AllReorts({ type, reload, set }) {
  const { data, error } = useFetchReports(
    `superAdmin/Report/Investment/allReport/${type}`,
    reload
  );
  const [show, setShow] = useState([]);
  const [reports, setResports] = useState({});
  useEffect(() => {
    if (!!data) {
      if (data.financial_report) {
        setShow(Object.keys(data.financial_report));
        setResports(data.financial_report);
      }
    }
  }, [data]);
  if (error) {
    return (
      <div>
        <h1 className=" text-xl font-bold text-center my-6 text-red-800 ">
          لا توجد استثمارات
        </h1>
      </div>
    );
  }
  return (
    <div>
      {reports && (
        <div>
          <h1 className=" text-xl font-bold text-center my-6 ">
            تقارير الاستثمار
          </h1>
          <ExportReports data={show} reports={reports} type={type} />
          {reports && (
            <table>
              <thead>
                <tr>
                  <th>اسم الاستثمار</th>
                  {/* <th>الاسهم المتاحة</th>
                  <th>الاسهم المباعة</th> */}
                  <th>الارباح بدون النسبة</th>
                  <th> نسبة المنصة</th>
                  <th>صافي الارباح</th>
                  <th>المدفوع</th>
                  <th>المتبقي</th>
                  <th>الدفعات</th>
                  <th>التفاصيل</th>
                </tr>
              </thead>
              <tbody>
                {show.map((item) => (
                  <tr key={item}>
                    <td className="  text-center ">
                      {reports[item].investment_title}
                    </td>
                    <td className="  text-center ">
                      {reports[item].revenue_before_platformFee} ر.س
                    </td>
                    <td className="  text-center ">
                      {reports[item].platformFee} ر.س
                    </td>
                    <td className="  text-center ">
                      {reports[item].revenue_after_platformFee} ر.س
                    </td>
                    <td className="  text-center ">
                      {reports[item].total_paid} ر.س
                    </td>
                    <td className="  text-center ">
                      {reports[item].remaining_amount} ر.س
                    </td>
                    <td className="  text-center ">
                      {reports[item].payments.length}
                    </td>
                    <td className="  text-center ">
                      <button
                        onClick={() => set(reports[item].investment_id)}
                        className=" text-sm bg-green-600 py-1 px-2 rounded-md text-white  "
                      >
                        عرض
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
}
