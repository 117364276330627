import React from "react";
import MyJoins from "../dashboard/pages/MyJoins/MyJoins";

export default function MyJoinsPage() {
  return (
    <div className=" py-12 px-6 md:p-12 ">
      <h1 className=" text-[36px] font-[800] text-center text-[#432315] ">
        {" "}
        مشاركاتي
      </h1>{" "}
      <div className=" my-6 ">
        <MyJoins />
      </div>
    </div>
  );
}
