import React, { useEffect, useState } from "react";
import useFetchWithReload from "../../../../utils/useGetWithReload";
import usePostFormData from "../../../../utils/usePostForm";
import DeletePlatform from "./DeletePlatform";

export default function RelatedPlatforms() {
  const [form, setForm] = useState({
    name: "",
    logo: null,
    type: "saudi",
  });
  const types = {
    saudi: "منصة سعودية",
    other: "اخري",
  };
  const handelChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };
  const [reload, setReload] = useState("");
  const re = () => {
    setReload(!reload);
  };
  const { data } = useFetchWithReload(`global/related_sites`, reload);

  const { postFormData, response } = usePostFormData(
    `superAdmin/related_sites/create`
  );
  const handelAdd = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("name", form.name);
    formdata.append("logo", form.logo);
    formdata.append("type", form.type);
    postFormData(formdata);
  };

  useEffect(() => {
    if (response) {
      re();
      setForm({ name: "", logo: null, type: "saudi" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const [deleteId, setDeleteId] = useState(null);
  return (
    <div>
      <h1 className=" text-xl my-4 text-center font-bold ">مواقع ذات صلة</h1>
      <section className=" my-4 ">
        <table>
          <thead>
            <tr>
              <th>الصورة</th>
              <th>اسم المنصة</th>
              <th>نوع المنصة</th>
              <th>حذف</th>
            </tr>
          </thead>
          {data && (
            <tbody>
              {data.map((i, inx) => (
                <tr key={inx}>
                  <td className="text-center">
                    <img
                      alt={i.name}
                      src={i.logo}
                      className=" h-[60px] mx-auto "
                    />
                  </td>
                  <td className="text-center">{i.name}</td>
                  <td className="text-center">{types[i.type]}</td>
                  <td className="text-center">
                    <section>
                      <button
                        onClick={() => setDeleteId(i.id)}
                        className=" text-red-600 text-[22px] "
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </section>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <h1 className=" text-xl my-4 text-center font-bold ">اضافة موقع</h1>
        <div className="bg-[#E8DCC0] rounded-[15px] p-4 mx-auto max-w-[1100px] my-4 ">
          <form
            onSubmit={handelAdd}
            className=" grid grid-cols-1 lg:grid-cols-2  items-center gap-8 md:gap-x-16 lg:gap-x-32   "
          >
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                اسم المنصة
              </h1>
              <input
                name="name"
                value={form.name}
                onChange={handelChange}
                className=" inpt "
                type="text"
                placeholder="  اسم المنصة"
                required
              />
            </section>
            <section className=" col-span-1 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                نوع المنصة
              </h1>
              <select
                name="type"
                value={form.type}
                onChange={handelChange}
                className=" inpt "
                required
              >
                <option value={"saudi"}>منصة سعودية</option>
                <option value={"other"}>اخري</option>
              </select>
            </section>
            <section className=" col-span-1 lg:col-span-2 ">
              <h1 className=" text-start my-1 text-[#432315] text-[20px]  font-[800] ">
                الصورة
              </h1>
              <section className=" flex items-center gap-2 ">
                <label>رفع صورة :</label>
                <input
                  value={form.logo ? form.logo.value : ""}
                  required
                  type="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => {
                    setForm({ ...form, logo: e.target.files[0] });
                  }}
                />
              </section>
            </section>
            <section className=" col-span-1 lg:col-span-2 text-center ">
              <button disabled={!form.name || !form.logo} className=" btn2 ">
                اضافة
              </button>
            </section>
          </form>
        </div>
        {deleteId && (
          <DeletePlatform set={re} id={deleteId} close={setDeleteId} />
        )}
      </section>
    </div>
  );
}
