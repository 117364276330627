import React, { useContext, useEffect, useState } from "react";
import CahngeCover from "./CahngeCover";
import ChangeInfo from "./ChangeInfo";
import Cookies from "js-cookie";
import useFetchGlobal from "../../../utils/useGetGlobal";
import Error from "../../../components/Error/Error";
import NoProfileImage from "../../../components/NoProfileImage";
import { openMessage } from "../../../utils/openMessageStore";

export default function Header() {
  const id = Cookies.get("value");
  const { data, error } = useFetchGlobal(`global/CamelOwners/show/${id}`);

  const [show, setShow] = useState({
    name: "",
    lastName: "",
    img: "",
    loc: "",
    cover: "",
    desc: "",
    camel_brand_name: "",
    camel_brand_logo: "",
  });
  useEffect(() => {
    if (!!data) {
      setShow({
        name: `${data["Camel Owners"].first_name}`,
        lastName: data["Camel Owners"].last_name,
        loc: data["Camel Owners"].address,
        cover: data["Camel Owners"].cover_image,
        desc: data["Camel Owners"].bio,
        img: data["Camel Owners"].image,
        camel_brand_logo: data["Camel Owners"].camel_brand_logo,
        camel_brand_name: data["Camel Owners"].camel_brand_name,
      });
    }
  }, [data]);

  const {
    desc,
    name,
    lastName,
    loc,
    cover,
    img,
    camel_brand_logo,
    camel_brand_name,
  } = show;
  const [openChangeCover, setOpenChangeCoverState] = useState(false);
  const [openInfo, setInfoState] = useState(false);
  const { openMessageState } = useContext(openMessage);

  const open = () => {
    setInfoState(true);
  };
  if (error) {
    return <Error />;
  }
  return (
    <div>
      <div className="  relative   ">
        {cover ? (
          <div className="  top-0 left-0 bg-origin-content bg-cover   w-full h-full">
            <img
              src={`${process.env.REACT_APP_URL}/${cover}`}
              alt="Vision"
              className=" left-0 top-0 h-full min-h-[190px] w-full object-cover"
              // className=" left-0 top-0 h-full min-h-[150px] w-full"
            />
          </div>
        ) : (
          <div className=" h-[180px] md:h-[280px]  py-12 px-8 md:px-16 ">
            <div className=" bg-[#F5EFDC] flex items-center justify-center absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full">
              <section>
                {" "}
                <img
                  src="./imgs/navLogoCropped.png"
                  className=" w-[250px] opacity-45 "
                  alt="Logo"
                />{" "}
              </section>
            </div>
          </div>
        )}
        <div className=" h-2  right-0  absolute bottom-5 text-[#A59382] hover:text-[#432315] w-full flex items-center justify-end md:justify-center  mx-auto  ">
          <button
            onClick={() => setOpenChangeCoverState(true)}
            className="  p-2  flex items-center gap-2 "
          >
            <h1 className="hidden md:flex  font-[600] text-[20px]  ">
              تغير صورة الخلفية
            </h1>
            <i className="fa-solid fa-pen-to-square clear-start text-3xl "></i>
          </button>
        </div>
      </div>
      {/* <div className=" h-[180px] md:h-[280px]  py-12 px-8 md:px-16 relative   ">
        {cover ? (
          <div className=" absolute top-0 left-0 bg-origin-content bg-cover   w-full h-full">
            <img
              src={`${process.env.REACT_APP_URL}/${cover}`}
              alt="Vision"
              className="absolute left-0 top-0 h-full w-full object-cover"
            />
          </div>
        ) : (
          <div className=" bg-[#F5EFDC] flex items-center justify-center absolute top-0 left-0 bg-origin-content bg-cover   w-full h-[180px] md:h-[280px]">
            <section>
              <img
                src="./imgs/navLogoCropped.png"
                className=" w-[250px] opacity-45 "
                alt="Logo"
              />{" "}
            </section>
          </div>
        )}
        <div className=" h-2  right-0  absolute bottom-5 text-[#A59382] hover:text-[#432315] w-full flex items-center justify-end md:justify-center  mx-auto  ">
          <button
            onClick={() => setOpenChangeCoverState(true)}
            className="  p-2  flex items-center gap-2 "
          >
            <h1 className="hidden md:flex  font-[600] text-[20px]  ">
              تغير صورة الخلفية
            </h1>
            <i className="fa-solid fa-pen-to-square clear-start text-3xl "></i>
          </button>
        </div>
      </div> */}
      <div className=" md:flex  flex-row-reverse items-start gap-12 lg:gap-24  bg-[#E8DCC0] py-8 px-8 lg:px-24 ">
        <section className=" max-w-[290px]  mb-8 md:w-fit  mt-[-108px] md:mt-[-132px] z-10 relative  ">
          <div className=" h-2  left-[-30px] md:left-[-40px] lg:left-[-70px]  absolute top-[108px] md:top-[132px] text-[#A59382] hover:text-[#432315] w-full flex items-center justify-end   mx-auto  ">
            <button
              onClick={() => setInfoState(true)}
              className=" p-2  flex items-center "
            >
              <i className="fa-solid fa-pen-to-square clear-start text-3xl "></i>
            </button>
          </div>
          {img ? (
            <img
              src={`${process.env.REACT_APP_URL}/${img}`}
              alt="user profile"
              className="mx-auto  h-[120px] md:h-[150px]    rounded-[20px]  "
            />
          ) : (
            <div className="  mx-auto w-full ">
              <section className="mx-auto  h-[150px] w-[150px]  md:h-[200px] md:w-[200px] rounded-full bg-gray-200  flex items-center justify-center ">
                {" "}
                <i className="fa-solid fa-user  text-[#432315]/70 text-4xl md:text-8xl "></i>
              </section>
            </div>
          )}

          <h1 className="  text-center text-[#432315] font-sans text-[20px] lg:text-[36px] font-[600]  md:my-4 ">
            {name} {lastName}
          </h1>
          <h1 className=" text-center flex items-center justify-center text-[#432315] text-[12px] lg:text-[18px] ">
            <i className=" fa-solid fa-location-dot " />
            <h1 className=" mx-3 ">{loc}</h1>
          </h1>
        </section>

        <section className=" flex-1 ">
          <section className=" flex items-center gap-2 flex-wrap ">
            <section className=" flex items-center ">
              {camel_brand_logo && (
                <img
                  src={`${process.env.REACT_APP_URL}/${camel_brand_logo}`}
                  alt="صورة الوسم"
                  className=" relative z-10 w-[70px] h-[70px] rounded-full "
                />
              )}
              {camel_brand_name && (
                <section className=" relative z-0 my-4 bg-[#432315] px-10 mr-[-29px] text-[#E8DCC0] text-[18px] py-1 font-[600] text-center rounded-[10px] border border-[#432315] ">
                  {camel_brand_name}
                </section>
              )}
            </section>
            <section className=" my-4 text-[#432315] text-[18px] py-1 font-[600] text-center rounded-[10px] border border-[#432315] w-[150px] ">
              مالك الابل
            </section>
          </section>
          <p className=" text-[#432315] text-[18px] font-[700] leading-[35px] ">
            {desc}
          </p>
        </section>
      </div>
      {openMessageState && <NoProfileImage open={open} />}
      {openChangeCover && (
        <CahngeCover set={setShow} close={setOpenChangeCoverState} />
      )}
      {openInfo && (
        <ChangeInfo set={setShow} show={show} close={setInfoState} />
      )}
    </div>
  );
}
